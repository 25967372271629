import { type ReactElement } from 'react'
import { Stack, Typography, type BoxProps } from '@mui/material'

interface Props extends BoxProps {
	title: string
	action: ReactElement
}

export const Header = ({ title, action }: Props): ReactElement => {
	return (
		<Stack direction="row" justifyContent="space-between" sx={{ mb: 1, alignItems: 'center' }}>
			<Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main', ml: 1 }}>
				{title}
			</Typography>
			{action}
		</Stack>
	)
}

import { type UserName } from '@gqlTypes'
import { AutocompleteSelect, type AutocompleteSelectProps } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'

interface Props extends Partial<AutocompleteSelectProps> {
	users: UserName[]
	name: string
}

export const UsersAutocompleteSelect = ({ users, name, ...props }: Props): ReactElement => {
	const availableUsers = users.map(({ id, reverseFullName, email }) => ({
		value: id,
		label: `${reverseFullName}\n <${email}>`
	}))
	return <AutocompleteSelect name={name} items={availableUsers} {...props} />
}

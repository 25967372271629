import { Box, Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Attachment } from '@ui/fileUpload/Attachment'
import { type GetInterviewDetailsQuery } from '@gqlTypes'

interface Props {
	interview: GetInterviewDetailsQuery['getInterview']
}

export const IntegrationAttachments = ({ interview }: Props): ReactElement => {
	const integrationAttachments = interview.userIntegration.attachments
	const { t } = useCustomTranslation('translation:attachment')
	const displayName = interview.userIntegration.user?.fullName ?? ''

	return (
		<Box>
			<Typography>{t('integration.title', { displayName })}</Typography>
			<Stack spacing={1}>
				{integrationAttachments.map((attachment) => (
					<Attachment key={attachment.fileUrl} {...{ attachment, hasDeleteButton: false }} />
				))}
			</Stack>
		</Box>
	)
}

import { useGetAssessorsQuery } from '@gqlTypes'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { UsersAutocompleteSelect } from '@domains/user/components/UsersAutocompleteSelect'

export const AssessorSelect = (): ReactElement => {
	const { t } = useCustomTranslation()
	const { data } = useGetAssessorsQuery()
	const assessors = data?.getAssessors ?? []

	return (
		<UsersAutocompleteSelect
			users={assessors}
			name={'assessorIds'}
			label={t('interviews:columns.assessor')}
			isMultiple
		/>
	)
}

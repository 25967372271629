import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter from '../../features/lists/components/Filters/AutoCompleteFilter'
import { type ReactElement } from 'react'

export const InterviewTypesToCreate = (): ReactElement | null => {
	const { t } = useCustomTranslation()

	const { data, loading } = useMeQuery()
	if (loading || data?.me == null) return null
	const interviewTypesData = data.me.organization.interviewTypes
	const interviewTypes = interviewTypesData.map(({ id, label }) => {
		return { value: id, label }
	})

	if (interviewTypesData.length === 0) return null

	return (
		<InputFilter keyName="interviewTypesToCreate" operation={filterOperation.CONTAIN}>
			<AutoCompleteFilter
				options={interviewTypes}
				defaultValue=""
				label={t('lists:filter.interviewTypesToCreate')}
				noneValueLabel={t('lists:filter.noFilter')}
				isOptionsSorted={false}
				width="100%"
			/>
		</InputFilter>
	)
}

import { type ReactElement } from 'react'
import { Grid } from '@mui/material'
import { DateField } from '@domains/interviews/create/DateField'
import { TutorSelect } from '@domains/tutor/TutorSelect'
import { LocationSelect } from '@domains/location/LocationSelect'
import { PromotionSelect } from '@domains/promotion/PromotionSelect'
import { PositionStatusSelect } from '@domains/positionStatus/PositionStatusSelect'
import { ManagerSelect } from '@domains/manager/ManagerSelect'
import { BusinessUnitSelect } from '@domains/businessUnit/BusinessUnitSelect'
import { useOrganization } from '@domains/organization/useOrganization'
import { useGetManagersQuery } from '@gqlTypes'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const SchoolPositionTrackForm = (): ReactElement => {
	const { t } = useCustomTranslation()
	const { organization } = useOrganization()
	const { data } = useGetManagersQuery()

	const positionStatus = organization?.positionStatus ?? []
	const managers = data?.getManagers ?? []
	const promotion = organization?.promotions ?? []
	const locations = organization?.locations ?? []
	const businessUnits = organization?.businessUnits ?? []

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<DateField
					name="registerDate"
					label={t('organizationUsers:details.cards.tracks.form.registerDate')}
					textFieldProps={{ size: 'medium', fullWidth: true }}
				/>
			</Grid>
			{businessUnits.length > 0 ? (
				<Grid item xs={6}>
					<BusinessUnitSelect
						label={t('organizationUsers:details.cards.tracks.form.student.businessUnit.label')}
						noneValueLabel={t('organizationUsers:details.cards.tracks.form.student.businessUnit.none')}
					/>
				</Grid>
			) : null}
			{locations.length > 0 ? (
				<Grid item xs={6}>
					<LocationSelect
						label={t('organizationUsers:details.cards.tracks.form.student.location.label')}
						noneValueLabel={t('organizationUsers:details.cards.tracks.form.student.location.none')}
					/>
				</Grid>
			) : null}
			{promotion.length > 0 ? (
				<Grid item xs={6}>
					<PromotionSelect />
				</Grid>
			) : null}
			{positionStatus.length > 0 ? (
				<Grid item xs={6}>
					<PositionStatusSelect />
				</Grid>
			) : null}
			{managers.length > 0 ? (
				<>
					<Grid item xs={6}>
						<ManagerSelect
							label={t('organizationUsers:details.cards.tracks.form.student.manager.label')}
							noneValueLabel={t('organizationUsers:details.cards.tracks.form.student.manager.none')}
						/>
					</Grid>
					<Grid item xs={6}>
						<TutorSelect label={t('organizationUsers:details.cards.tracks.form.tutor.label')} />
					</Grid>
				</>
			) : null}
		</Grid>
	)
}

import { CommunityMembersList } from './list/CommunityMembersList'
import { CommunityMembersDetails } from './details/CommunityMembersDetails'
import { CommunityMembersDashboard } from './dashboard/CommunityMembersDashboard.page'
import { ScreenId } from '@gqlTypes'

enum Path {
	list = '/community-members',
	details = '/community-members/:userPositionId',
	dashboard = '/community-members/dashboard'
}

const routes = [
	{
		path: Path.list,
		children: <CommunityMembersList />
	},
	{
		path: Path.dashboard,
		children: <CommunityMembersDashboard />
	},
	{
		path: Path.details,
		children: <CommunityMembersDetails />,
		notExact: true
	}
]

export const CommunityMembersRouter = {
	roleId: ScreenId.CommunityMembers,
	paths: Path,
	routes
}

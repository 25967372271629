import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type AutoCompleteFilterProps } from '../../features/lists/components/Filters/AutoCompleteFilter'
import { useGetManagersQuery } from '@gqlTypes'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { UsersAutocompleteFilter } from '@domains/user/components/UsersAutocompleteFilter'

interface Props extends AutoCompleteFilterProps {
	keyLabel?: string
	keyName?: string
}

export default function ManagersFilter({ keyName = 'manager', keyLabel = 'managers', ...props }: Props): JSX.Element {
	const { t } = useCustomTranslation()
	const { data } = useGetManagersQuery()
	const managers = data?.getManagers ?? []

	return (
		<InputFilter keyName={keyName} operation={filterOperation.EQUAL}>
			<UsersAutocompleteFilter
				{...props}
				users={managers}
				label={t(`lists:filter.${keyLabel}.label`)}
				allValueLabel={t(`lists:filter.${keyLabel}.allLabel`)}
				width="100%"
			/>
		</InputFilter>
	)
}

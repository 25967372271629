import { DateTime, type ToRelativeUnit } from 'luxon'

// Remove seconds from unit for start to 0 minutes instead of 0 secondes
const unit = ['years', 'quarters', 'months', 'weeks', 'days', 'hours', 'minutes'] as unknown as ToRelativeUnit[]

// ex: Il y a 5 min
export const relativeToNow = (dateTime: string): string => {
	return DateTime.fromISO(dateTime).toRelative({ unit }) ?? ''
}

export const currentDate = (): string => {
	return DateTime.now().startOf('day').toISO()
}

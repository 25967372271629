import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { type ReactElement, type ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'

interface Props {
	children?: ReactNode
	actions?: (isHovered: boolean) => ReactNode
	to?: string
	navigationState?: Record<string, unknown>
	linkLabel?: string
	hasLink?: boolean
	avatar: ReactNode
	title: string
}

export const AvatarCard = ({
	actions,
	children,
	to,
	linkLabel = '',
	hasLink = true,
	avatar,
	title,
	navigationState
}: Props): ReactElement | null => {
	const [isHovered, setIsHovered] = useState(false)
	return (
		<Link to={{ pathname: to, state: navigationState }} style={{ display: 'inline-flex' }}>
			<Box
				onMouseOver={() => {
					setIsHovered(true)
				}}
				onMouseLeave={() => {
					setIsHovered(false)
				}}
				sx={{ mt: 1 }}
			>
				<Box sx={{ position: 'absolute', mt: -2, ml: 2 }}>{avatar}</Box>
				<Card
					sx={{
						boxShadow: 3,
						width: 270,
						borderRadius: 3,
						display: 'inline-block',
						height: '100%',
						'&:hover': { boxShadow: 10 }
					}}
				>
					<Stack justifyContent="space-between" sx={{ height: '100%' }}>
						<CardContent sx={{ paddingBottom: 0 }}>
							<Stack
								direction="row"
								justifyContent="end"
								alignItems="start"
								sx={{ minHeight: 42 }}
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
								}}
							>
								{actions == null ? null : actions(isHovered)}
							</Stack>
							<Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: 'grays.gray5' }}>{title}</Typography>
							{children}
						</CardContent>
					</Stack>
				</Card>
			</Box>
		</Link>
	)
}

import { useEffect, type ReactElement } from 'react'
import {
	type UpdateUserPositionInput,
	type UserPositionListNodeFragment,
	useGetManagersQuery,
	useMeQuery
} from '@gqlTypes'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from '@mui/material'
import { UserAvatar } from '@ui/UserAvatar'
import { usePositionUpdate } from '@domains/students/list/usePositionUpdate'
import { FormProvider, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { DialogCloseButton } from '@ui/dialog/DialogCloseButton'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { UsersAutocompleteSelect } from '@domains/user/components/UsersAutocompleteSelect'

interface Props {
	position: UserPositionListNodeFragment
	handleClose: () => void
	isOpen: boolean
}

export const EmployeeCardEditDialog = ({ position, handleClose, isOpen }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { update } = usePositionUpdate(position)
	const defaultValues = {
		businessUnit: position.businessUnit ?? '',
		location: position.location ?? '',
		manager: position.manager ?? '',
		communityLabels: position.communityLabels ?? [],
		userInfo: { publicTitle: position.userInfo?.publicTitle ?? '' }
	}
	const formMethods = useForm<UpdateUserPositionInput>({ mode: 'onChange', defaultValues })
	const { handleSubmit, reset } = formMethods
	useEffect(() => {
		reset(defaultValues)
	}, [position])

	const { data, loading } = useMeQuery({ fetchPolicy: 'cache-only' })
	const { data: dataManagers } = useGetManagersQuery()
	const managers = dataManagers?.getManagers ?? []
	if (loading || data?.me == null) return null
	const organization = data.me.organization
	const availableLocations = organization.locations.map((loc: string) => ({ value: loc, label: loc }))
	const availableBusinessUnits = organization?.businessUnits.map((bu: string) => ({ value: bu, label: bu }))
	const availableCommunityLabels = organization.communityLabels.map((Label) => ({ value: Label, label: Label }))

	const isBusinessUnitRestricted = (data.me.businessUnit ?? '') !== ''
	const isLocationRestricted = (data.me.location ?? '') !== ''

	const updateUserPosition = (values: UpdateUserPositionInput): void => {
		update(values)
		handleClose()
	}

	const userRef = position.userInfo
	if (userRef == null) return null

	return (
		<Dialog onClose={handleClose} open={isOpen} fullWidth maxWidth="sm">
			<FormProvider {...formMethods}>
				<DialogCloseButton onClick={handleClose} />
				<DialogTitle color="primary" sx={{ mb: 3, fontWeight: 'bold' }}>
					{t('employees:update.title')}
				</DialogTitle>
				<DialogContent>
					<Stack direction="row" gap={2} sx={{ mb: 5 }} alignItems="center">
						<UserAvatar user={userRef} />
						<Box>
							<Typography sx={{ fontWeight: 'bold' }}>{userRef.displayName ?? ''}</Typography>
							<Typography>{position?.trackLabel ?? ''}</Typography>
						</Box>
					</Stack>
					<Grid container spacing={4}>
						<Grid item xs={12} sm={6}>
							<AutocompleteSelect
								name="businessUnit"
								items={availableBusinessUnits}
								label={t('employees:columns.businessUnit')}
								noneValueLabel={t('employees:cell.businessUnit.none')}
								isRestricted={isBusinessUnitRestricted}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<AutocompleteSelect
								name="location"
								items={availableLocations}
								label={t('employees:columns.location')}
								noneValueLabel={t('employees:cell.location.none')}
								isRestricted={isLocationRestricted}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<UsersAutocompleteSelect
								name="manager"
								users={managers}
								label={t('employees:columns.manager')}
								noneValueLabel={t('employees:cell.manager.none')}
								isRestricted={data.me.isRestrictedAccess ?? false}
							/>
						</Grid>
						{availableCommunityLabels.length > 0 ? (
							<Grid item xs={12} sm={12}>
								<AutocompleteSelect
									name="communityLabels"
									items={availableCommunityLabels}
									label={t('employees:columns.communityLabels')}
									isMultiple
								/>
							</Grid>
						) : null}
						<Grid item xs={12} sm={6}>
							<InputTextFieldForm
								fullWidth
								name="userInfo.publicTitle"
								label={t('employees:columns.publicTitle')}
								size="medium"
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{t('employees:update.cancel')}</Button>
					<LoadingButton onClick={handleSubmit(updateUserPosition)} color="secondary" variant="contained">
						{t('employees:update.edit')}
					</LoadingButton>
				</DialogActions>
			</FormProvider>
		</Dialog>
	)
}

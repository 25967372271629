import TextField from './fields/TextField'
import { TableLayout } from '@ui/core/TableLayout'
import CardLayout from '@ui/core/CardLayout'
import ListField from './fields/ListField'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import CheckBoxField from './fields/CheckBoxField'
import PhoneField from './fields/PhoneField'
import { useWatch, type Control, type DeepMap, type FieldError, type FieldValues } from 'react-hook-form'
import { type GetUserQuery } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { Grid } from '@mui/material'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'

interface Props {
	control: Control
	defaultValues?: GetUserQuery['getUser']
	errors: DeepMap<FieldValues, FieldError>
	managers: { value: string; label: string }[]
}

export default function InformationCard({ control, defaultValues, errors, managers }: Props): JSX.Element {
	const { t } = useCustomTranslation()
	const mobile: string | undefined = useWatch({ name: 'mobile' })
	const hasMobile: boolean = mobile != null && mobile !== ''

	const languageItems = [
		{ id: 'fr', name: 'Français' },
		{ id: 'en', name: 'English' }
	]
	return (
		<CardLayout title={t('organizationUsers:details.cards.information')}>
			<TableLayout>
				<CheckBoxField name="active" control={control} defaultValue={defaultValues?.active ?? undefined} />
				<TextField
					name="firstname"
					control={control}
					defaultValue={defaultValues?.firstname ?? undefined}
					required
					error={errors.firstname}
				/>
				<TextField
					name="lastname"
					control={control}
					defaultValue={defaultValues?.lastname ?? undefined}
					required
					error={errors.lastname}
				/>
				<TextField
					name="email"
					control={control}
					defaultValue={defaultValues?.email ?? undefined}
					error={errors.email}
					rules={{
						validate: (email: string) =>
							(email != null && email !== '') || hasMobile ? true : t('form:errors.required')
					}}
				/>
				<TextField
					name="publicTitle"
					control={control}
					defaultValue={defaultValues?.publicTitle ?? undefined}
					error={errors.publicTitle}
				/>
				<TextField name="visioLink" control={control} defaultValue={defaultValues?.visioLink ?? undefined} />
				<ListField
					name="language"
					control={control}
					items={languageItems}
					defaultValue={defaultValues?.language ?? undefined}
				/>
				<TableLayoutRow
					title={t('organizationUsers:details.formFields.managerId.title')}
					tooltip={t('organizationUsers:details.formFields.managerId.tooltip')}
				>
					<Grid sx={{ width: '100%' }}>
						<AutocompleteSelect name="managerId" items={managers} inputProps={{ size: 'small' }} />
					</Grid>
				</TableLayoutRow>
				<PhoneField
					name="mobile"
					control={control}
					defaultValue={defaultValues?.mobile ?? undefined}
					error={errors.mobile}
				/>
			</TableLayout>
		</CardLayout>
	)
}

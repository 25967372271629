import { type ReactElement } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useForm, FormProvider } from 'react-hook-form'
import {
	GetUserPositionsDocument,
	useSubscribeUserToPositionMutation,
	type SubscribeUserToPositionMutationVariables
} from '@gqlTypes'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { SchoolPositionTrackForm } from './SchoolPositionTrackForm'
import { CompanyPositionTrackForm } from './CompanyPositionTrackForm'
import { CommunityPositionTrackForm } from './CommunityPositionTrackForm'
import { currentDate } from '#helpers/date.helper'
import { enqueueSnackbar } from 'notistack'
import { Severities } from '@application/snackbar/Severities'
import { useHistory, useParams } from 'react-router-dom'
import useUserDetails from '../useUserDetails'
import { OrganizationUsersRouter } from '../OrganizationUsers.router'
import { generateRoute } from '../../../../router/routes'

interface FormInputs {
	trackId: string
	registerDate?: string
	businessUnit?: string
	location?: string
	promotion?: string
	status?: string
	manager?: string
	externalAssessorId?: string
}

export const PositionTrackCardEditDialog = (): ReactElement => {
	const { userId, isSkippable } = useParams<{ userId: string; isSkippable: string }>()
	const { availablePositions } = useUserDetails()
	const history = useHistory()

	const { t } = useCustomTranslation()
	const refetchQueries = [GetUserPositionsDocument]
	const [subscribePosition, { loading: isSubmitting }] = useSubscribeUserToPositionMutation({ refetchQueries })
	const defaultValues = {
		trackId: '',
		registerDate: currentDate(),
		businessUnit: '',
		location: '',
		promotion: '',
		status: '',
		manager: '',
		externalAssessorId: ''
	}

	const handleClose = (): void => {
		history.push(generateRoute(OrganizationUsersRouter.paths.details, { userId }))
	}

	const formMethods = useForm<FormInputs>({
		defaultValues
	})

	const { handleSubmit, watch } = formMethods

	const selectedTrackId = watch('trackId')
	const selectedTrack = availablePositions.find((pos) => pos.value === selectedTrackId)
	const selectedTrackType = selectedTrack?.category ?? ''

	const onSubmit = async (formData: FormInputs): Promise<void> => {
		const { trackId, registerDate, businessUnit, location, promotion, status, manager, externalAssessorId } = formData
		const variables: SubscribeUserToPositionMutationVariables = {
			userId,
			trackId,
			subscribeUserPositionInput: {
				registerDate: registerDate ?? '',
				businessUnit: businessUnit ?? '',
				location: location ?? '',
				promotion: promotion ?? '',
				status: status ?? '',
				manager: manager ?? '',
				externalAssessorId: externalAssessorId ?? ''
			}
		}

		await subscribePosition({ variables })

		handleClose()
		enqueueSnackbar(t('organizationUsers:details.cards.tracks.success'), { variant: Severities.success })
	}

	const trackForms: Record<string, ReactElement> = {
		school: <SchoolPositionTrackForm />,
		company: <CompanyPositionTrackForm />,
		community: <CommunityPositionTrackForm />
	}

	return (
		<Dialog open={true} onClose={handleClose} fullWidth>
			<FormProvider {...formMethods}>
				<DialogTitle>{t('organizationUsers:details.cards.tracks.formTitle')}</DialogTitle>
				<DialogContent>
					<Grid container spacing={2} sx={{ mt: 1 }}>
						<Grid item xs={12}>
							<AutocompleteSelect
								name="trackId"
								items={availablePositions}
								label={t('organizationUsers:details.cards.tracks.form.track')}
							/>
						</Grid>
						<Grid item xs={12}>
							{trackForms[selectedTrackType]}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>
						{t(`organizationUsers:details.cards.tracks.${isSkippable != null ? 'skip' : 'cancel'}`)}
					</Button>
					<LoadingButton onClick={handleSubmit(onSubmit)} loading={isSubmitting} variant="contained">
						{t('organizationUsers:details.cards.tracks.submit')}
					</LoadingButton>
				</DialogActions>
			</FormProvider>
		</Dialog>
	)
}

import {
	CircularProgress,
	Autocomplete,
	type SxProps,
	type TextFieldProps,
	TextField,
	FormHelperText,
	Box,
	createFilterOptions
} from '@mui/material'
import { Controller, useFormContext, type FieldValues } from 'react-hook-form'
import React, { useEffect, type ReactElement } from 'react'

export interface Item {
	value: string | number
	label: string
	inputValue?: string
}

export interface AutocompleteSelectProps {
	name: string
	items: Item[]
	label?: string
	loading?: boolean
	sx?: SxProps
	rules?: FieldValues
	noneValueLabel?: string
	isRestricted?: boolean
	inputProps?: TextFieldProps
	orderItems?: boolean
	fullWidth?: boolean
	disableClearable?: boolean
	disabled?: boolean
	hasError?: boolean
	errorMessage?: string
	onValueChange?: (value: string) => void
	isMultiple?: boolean
	iconComponent?: ReactElement
	createOptionLabel?: string
}

export const AutocompleteSelect = ({
	name,
	items,
	label = '',
	loading = false,
	sx = {},
	rules = {},
	noneValueLabel = '',
	isRestricted = false,
	inputProps,
	orderItems = true,
	fullWidth = false,
	disableClearable = false,
	disabled = false,
	hasError = false,
	errorMessage = '',
	onValueChange = () => {},
	isMultiple = false,
	iconComponent,
	createOptionLabel = ''
}: AutocompleteSelectProps): ReactElement => {
	const [localItems, setLocalItems] = React.useState<Item[]>([])

	useEffect(() => {
		setLocalItems(items)
	}, [items.map(({ value }) => value).join()])

	const orderedItems = React.useMemo(() => {
		const orderedItems = orderItems ? [...localItems].sort((a, b) => a.label.localeCompare(b.label)) : [...localItems]
		if (noneValueLabel !== '') orderedItems.push({ label: noneValueLabel, value: '' })
		return orderedItems
	}, [localItems])
	const isDisabled = (localItems.length < 2 && isRestricted) || disabled
	const filter = createFilterOptions<Item>()
	const formContext = useFormContext()
	const errors = formContext.formState?.errors ?? {}
	const error = errors[name]

	const isRequiredLabel = rules?.required != null && label !== ''
	const computedLabel = isRequiredLabel ? `${label} *` : label

	return (
		<Box sx={{ flex: 1 }}>
			<Controller
				rules={rules}
				name={name}
				render={({ onChange, value }) => {
					const selectedItem = React.useMemo(() => {
						if (isMultiple) {
							return orderedItems.filter((item) => value.includes(item.value)) ?? null
						} else {
							return orderedItems.find((item) => item.value === value) ?? null
						}
					}, [orderedItems, value])
					return (
						<Autocomplete
							sx={sx}
							options={orderedItems}
							onChange={(_: unknown, newValue: Item | Item[] | null) => {
								if (Array.isArray(newValue)) {
									onChange(newValue.map((item) => item.value))
								} else {
									if (newValue?.inputValue !== undefined) {
										setLocalItems([...items, { value: newValue.value, label: newValue.inputValue }])
									}
									onChange(newValue?.value ?? '')
									onValueChange(newValue?.value.toString() ?? '')
								}
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params)

								const { inputValue } = params
								// Suggest the creation of a new value
								const isExisting = options.some((option) => inputValue === option.label)
								if (inputValue !== '' && !isExisting && createOptionLabel !== '') {
									filtered.push({
										inputValue,
										label: `${createOptionLabel} "${inputValue}"`,
										value: inputValue
									})
								}

								return filtered
							}}
							value={selectedItem}
							loading={loading}
							disabled={isDisabled}
							fullWidth={fullWidth}
							disableClearable={disableClearable}
							multiple={isMultiple}
							renderInput={(params) => (
								<TextField
									{...params}
									{...inputProps}
									variant="outlined"
									label={computedLabel}
									error={hasError || error != null}
									InputProps={{
										...params.InputProps,
										startAdornment: <>{iconComponent ?? params.InputProps.startAdornment}</>,
										endAdornment: (
											<>
												{loading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</>
										)
									}}
								/>
							)}
						/>
					)
				}}
			/>
			<FormHelperText error={hasError}>{errorMessage}</FormHelperText>
		</Box>
	)
}

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useForm } from 'react-hook-form'
import Center from '@ui/layout/Center'
import { LoadingButton } from '@mui/lab'
import { firebaseAuth, storage } from '@infrastructure/firebase/firebase.helper'
import { usePutUsersFromFileMutation } from '@gqlTypes'
import { UserImportResult } from './UserImportResult'
import { ErrorHandler } from '#helpers/error.helper'
import { useState, type ReactElement } from 'react'
import { Alert, DialogContent, Stack, Typography } from '@mui/material'
import { CancelFormButton } from '@ui/form/buttons/CancelFormButton'

interface Props {
	isOpen: boolean
	close: () => void
}

interface FormInputs {
	files: File[]
}

export const UserImportModal = ({ isOpen, close }: Props): ReactElement => {
	const { register, formState, reset, getValues } = useForm<FormInputs>({ mode: 'onChange' })
	const [putUsersFromFile, { loading, data }] = usePutUsersFromFileMutation()
	const { t } = useCustomTranslation()
	const [isSubmitted, setIsSubmitted] = useState(false)

	const onSubmit = (): void => {
		;(async () => {
			const { files } = getValues()
			const file = files[0]
			if (file === undefined) return

			const userId = firebaseAuth().currentUser?.uid ?? ''
			const directory = `users/${userId}/`
			const CSVUrl = `${directory}${file.name}`
			await storage().ref().child(CSVUrl).put(file)
			await putUsersFromFile({ variables: { CSVUrl } })
			setIsSubmitted(true)
			reset()
		})().catch(ErrorHandler)
	}

	return (
		<Dialog open={isOpen} onClose={close} fullWidth maxWidth="md">
			<DialogTitle>
				<>{t(`modal:userImport.${isSubmitted ? 'reportTitle' : 'title'}`)}</>
			</DialogTitle>
			<Divider />
			<DialogContent>
				{isSubmitted ? <UserImportResult report={data?.putUsersFromFile} /> : null}
				<form>
					{!isSubmitted ? (
						<>
							<Alert severity="info">{t('modal:userImport.information')}</Alert>
							<Center>{!isSubmitted ? <input ref={register} name="files" type="file" /> : null}</Center>
							<Center>
								<Typography>{t('modal:userImport.fileInformation')}</Typography>
							</Center>
						</>
					) : null}

					<Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
						<CancelFormButton
							variant="text"
							onClick={() => {
								setIsSubmitted(false)
								close()
							}}
						/>
						<LoadingButton
							color="secondary"
							onClick={() => {
								isSubmitted ? setIsSubmitted(false) : onSubmit()
							}}
							loading={loading}
							disabled={!formState.isValid}
						>
							{t(`modal:userImport.${isSubmitted ? 'newImport' : 'import'}`)}
						</LoadingButton>
					</Stack>
				</form>
			</DialogContent>
		</Dialog>
	)
}

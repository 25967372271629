import { pick } from '#helpers/object.helper'
import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'
import { type UpdateMyOrganizationInput, useMeQuery, useUpdateMyOrganizationMutation } from '@gqlTypes'
import { useEffect } from 'react'
import { useForm, type UseFormMethods } from 'react-hook-form'

interface UseUpdateMyOrganizationFormReturn {
	save: (updateMyAccountInput: UpdateMyOrganizationInput) => Promise<void>
	isSaving: boolean
	onError: () => void
	formMethods: UseFormMethods<UpdateMyOrganizationInput>
}

export const useUpdateMyOrganizationForm = (): UseUpdateMyOrganizationFormReturn => {
	const { data } = useMeQuery()
	const organiaztion = data?.me?.organization
	const { onError, onCompleted } = useGqlSnackBar('organization:myOrganization.generalSettings.update')
	const [update, { loading: isSaving }] = useUpdateMyOrganizationMutation({ onError, onCompleted })

	const defaultValues: UpdateMyOrganizationInput = {
		defaultLanguage: '',
		interviewTypeIds: [],
		name: '',
		website: '',
		about: ''
	}

	const formMethods = useForm<UpdateMyOrganizationInput>({ mode: 'onChange', defaultValues })
	const { reset } = formMethods

	const save = async (updateMyOrganizationInput: UpdateMyOrganizationInput): Promise<void> => {
		reset(updateMyOrganizationInput)
		await update({ variables: { updateMyOrganizationInput } })
	}

	useEffect(() => {
		if (organiaztion == null) return
		reset({
			...pick(organiaztion, 'defaultLanguage', 'website', 'about'),
			interviewTypeIds: (organiaztion?.interviewTypes ?? []).map(({ id }) => id),
			name: organiaztion.name ?? ''
		})
	}, [reset, organiaztion])

	return {
		save,
		isSaving,
		onError,
		formMethods
	}
}

import { type ReactElement } from 'react'
import { Grid } from '@mui/material'
import { DateField } from '@domains/interviews/create/DateField'
import { TutorSelect } from '@domains/tutor/TutorSelect'
import { ManagerSelect } from '@domains/manager/ManagerSelect'
import { PositionStatusSelect } from '@domains/positionStatus/PositionStatusSelect'
import { BusinessUnitSelect } from '@domains/businessUnit/BusinessUnitSelect'
import { LocationSelect } from '@domains/location/LocationSelect'
import { useGetManagersQuery } from '@gqlTypes'
import { useOrganization } from '@domains/organization/useOrganization'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const CommunityPositionTrackForm = (): ReactElement => {
	const { t } = useCustomTranslation()
	const { organization } = useOrganization()
	const { data } = useGetManagersQuery()

	const positionStatus = organization?.positionStatus ?? []
	const managers = data?.getManagers ?? []
	const locations = organization?.locations ?? []
	const businessUnits = organization?.businessUnits ?? []

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<DateField
					name="registerDate"
					label={t('organizationUsers:details.cards.tracks.form.registerDate')}
					textFieldProps={{ size: 'medium', fullWidth: true }}
				/>
			</Grid>
			{businessUnits.length > 0 ? (
				<Grid item xs={6}>
					<BusinessUnitSelect />
				</Grid>
			) : null}

			{locations.length > 0 ? (
				<Grid item xs={6}>
					<LocationSelect />
				</Grid>
			) : null}

			{positionStatus.length > 0 ? (
				<Grid item xs={6}>
					<PositionStatusSelect />
				</Grid>
			) : null}

			{managers.length > 0 ? (
				<>
					<Grid item xs={6}>
						<ManagerSelect />
					</Grid>
					<Grid item xs={6}>
						<TutorSelect label={t('organizationUsers:details.cards.tracks.form.tutor.label')} />
					</Grid>
				</>
			) : null}
		</Grid>
	)
}

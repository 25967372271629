import { type ReactNode, useCallback } from 'react'
import Typography from '@mui/material/Typography'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { BooleanInput } from '@ui/core/BooleanInput'
import { generateRoute } from '../../../router/routes'
import NavButtonIcon from '@ui/buttons/NavButtonIcon'
import EditIcon from '@mui/icons-material/Edit'
import moment from 'moment'
import { useMeQuery, type User } from '@gqlTypes'
import { LoginAs } from '@application/auth/LoginAs'
import { OrganizationUsersRouter } from '../details/OrganizationUsers.router'

interface Cell {
	label: string
	width?: number | string
	align?: string
	renderCell: (param: User) => ReactNode
	isDisplay?: boolean
}

export const useOrganizationUsersColumns = (): (() => Cell[]) => {
	const { t } = useCustomTranslation()
	const { data } = useMeQuery()
	const isSuperAdmin = data?.me?.isSuperAdmin ?? false

	return useCallback(() => {
		return [
			{
				label: '',
				width: 50,
				renderCell: (user) => (
					<NavButtonIcon
						route={generateRoute(OrganizationUsersRouter.paths.details, { userId: user.id ?? '' })}
						icon={<EditIcon />}
						tooltipTitle={t('organizationUsers:list.columns.link')}
					/>
				)
			},
			isSuperAdmin
				? {
						key: 'loginAs',
						label: '',
						renderCell: (user) => <LoginAs uid={user?.id ?? ''} />
					}
				: {},
			{
				key: 'lastname',
				label: t('organizationUsers:list.columns.lastname'),
				renderCell: (user) => {
					const style = (user.active ?? false) ? {} : { color: '#CCC' }
					return <Typography style={style}>{user?.lastname}</Typography>
				}
			},
			{
				key: 'firstname',
				label: t('organizationUsers:list.columns.firstname'),
				renderCell: (user) => {
					const style = (user.active ?? false) ? {} : { color: '#CCC' }
					return <Typography style={style}>{user?.firstname}</Typography>
				}
			},
			{
				key: 'email',
				label: t('organizationUsers:list.columns.email'),
				renderCell: (user) => {
					const style = (user.active ?? false) ? {} : { color: '#CCC' }
					return <Typography style={style}>{user?.email}</Typography>
				}
			},
			{
				key: 'creationDate',
				label: t('organizationUsers:list.columns.creationDate'),
				renderCell: (user) => {
					const style = (user.active ?? false) ? {} : { color: '#CCC' }
					return <Typography style={style}>{moment(user?.creationDate).format('DD/MM/YYYY')}</Typography>
				}
			},
			{
				key: 'language',
				label: t('organizationUsers:list.columns.language'),
				renderCell: (user) => {
					const style = (user.active ?? false) ? {} : { color: '#CCC' }
					return <Typography style={style}>{user?.language}</Typography>
				}
			},
			{
				key: 'role',
				label: t('organizationUsers:list.columns.role'),
				renderCell: (user) => {
					const style = (user.active ?? false) ? {} : { color: '#CCC' }
					return <Typography style={style}>{user?.role?.name}</Typography>
				}
			},
			{
				key: 'isAssignable',
				label: t('organizationUsers:list.columns.isAssignable'),
				renderCell: (user) => <BooleanInput value={user.isAssignable ?? false} onChange={() => {}} noLabel />
			},
			{
				key: 'isManager',
				label: t('organizationUsers:list.columns.isManager'),
				renderCell: (user) => <BooleanInput value={user.isManager ?? false} onChange={() => {}} noLabel />
			},
			{
				key: 'isRestrictedAccess',
				label: t('organizationUsers:list.columns.isRestrictedAccess'),
				renderCell: (user) => <BooleanInput value={user.isRestrictedAccess ?? false} noLabel />
			},
			{
				key: 'hasMobileAccount',
				label: t('organizationUsers:list.columns.hasMobileAccount'),
				renderCell: (user) => <BooleanInput value={user.hasMobileAccount ?? false} onChange={() => {}} noLabel />
			},
			{
				key: 'visioLink',
				label: t('organizationUsers:list.columns.visioLink'),
				renderCell: (user) => {
					const style = (user.active ?? false) ? {} : { color: '#CCC' }
					return <Typography style={style}>{user?.visioLink}</Typography>
				}
			}
		]
	}, [t])
}

import { useCustomTranslation } from '#translations/useCustomTranslation'
import { FilterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type AutoCompleteFilterProps } from './AutoCompleteFilter'
import { useGetAssessorsQuery } from '@gqlTypes'
import { type ReactElement } from 'react'
import { UsersAutocompleteFilter } from '@domains/user/components/UsersAutocompleteFilter'

interface Props extends AutoCompleteFilterProps {
	keyName: string
	operation?: FilterOperation
}

export const AssessorsFilter = ({
	keyName,
	operation = FilterOperation.EQUAL,
	...props
}: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { data } = useGetAssessorsQuery()
	const assessors = data?.getAssessors ?? []

	return (
		<InputFilter keyName={keyName} operation={operation}>
			<UsersAutocompleteFilter
				{...props}
				users={assessors}
				label={t('lists:filter.assessor.label')}
				allValueLabel={t('lists:filter.assessor.allLabel')}
				width="100%"
			/>
		</InputFilter>
	)
}

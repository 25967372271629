import { OrganizationConfiguration } from './myOrganization/OrganizationConfiguration.page'
import { TrackOffersConfiguration } from './myOrganization/TrackOffersConfiguration.page'
import { TrackPositionsConfiguration } from './myOrganization/TrackPositionsConfiguration.page'
import { InterviewTemplateConfiguration } from './myOrganization/InterviewTemplateConfiguration.page'

enum Path {
	default = '/my-organization/',
	settings = '/my-organization/settings',
	trackOffers = '/my-organization/track-offers',
	trackPositions = '/my-organization/track-positions',
	interviewTemplates = '/my-organization/interview-template'
}

const routes = [
	{
		path: Path.default,
		children: <OrganizationConfiguration />
	},
	{
		path: Path.settings,
		children: <OrganizationConfiguration />
	},
	{
		path: Path.trackOffers,
		children: <TrackOffersConfiguration />
	},
	{
		path: Path.trackPositions,
		children: <TrackPositionsConfiguration />
	},
	{
		path: Path.interviewTemplates,
		children: <InterviewTemplateConfiguration />
	}
]

export const organizationRouter = {
	path: Path,
	routes
}

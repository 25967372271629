import { useCustomTranslation } from '#translations/useCustomTranslation'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'

export const NameInput = (): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<InputTextFieldForm
			name="name"
			rules={{
				required: t('form:errors.required')
			}}
			label={t('organization:myOrganization.generalSettings.name.label')}
		/>
	)
}

import { Fab, Popover } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useRef, useState, type ReactElement } from 'react'
import ForumIcon from '@mui/icons-material/Forum'
import { ChatContent } from './ChatContent'
import { useReactiveVar } from '@apollo/client/react/hooks'
import { makeVar } from '@apollo/client'

interface Props {
	userId: string
	displayName: string
	avatarUrl?: string
}

export const openChat = makeVar(false)

export const ChatButton = ({ userId, displayName, avatarUrl }: Props): ReactElement | null => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const divRef = useRef(null)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (): void => {
		setAnchorEl(null)
	}

	const isOpen = useReactiveVar(openChat)
	const open = Boolean(anchorEl)

	useEffect(() => {
		if (isOpen) setAnchorEl(divRef.current)
	}, [isOpen])

	const id = open ? 'simple-popover' : undefined
	return (
		<Box
			sx={{
				position: 'fixed',
				bottom: 30,
				width: '960px',
				justifyContent: 'flex-end',
				display: 'flex'
			}}
		>
			<Fab color="secondary" aria-label="add" ref={divRef} onClick={handleClick}>
				<ForumIcon />
			</Fab>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				slotProps={{ paper: { sx: { borderRadius: '12px', marginTop: '-10px' } } }}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
			>
				{open ? <ChatContent {...{ userId, displayName, avatarUrl }} /> : null}
			</Popover>
		</Box>
	)
}

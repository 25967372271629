import CandidacyTrackButton from './filter/CandidacyTrackButton'
import EmployeeTrackButton from './filter/EmployeeTrackButton'
import { useLocation } from 'react-router-dom'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { LeftBar } from '@features/lists/components/LeftBar'
import AddIcon from '@mui/icons-material/Add'
import { TrackImport } from '../details/TrackImport'
import { type Exact } from '@gqlTypes'
import { type ApolloQueryResult } from '@apollo/client'
import { IconAction } from '@ui/icon/IconAction'

interface Props {
	refetch: (params?: Partial<Exact<{ cursor: string; limit: number }>>) => Promise<ApolloQueryResult<unknown>>
}

export default function ListBar({ refetch }: Props): JSX.Element {
	const location = useLocation().pathname
	const { t } = useCustomTranslation()
	const canSwitchType = !location.includes('my-organization')

	return (
		<LeftBar>
			{canSwitchType ? (
				<>
					<CandidacyTrackButton />
					<EmployeeTrackButton />
				</>
			) : null}

			<IconAction tooltip={t('tracks:list.bar.button.new')} to={`${location}/new`}>
				<AddIcon />
			</IconAction>
			<TrackImport refetch={refetch} />
		</LeftBar>
	)
}

import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type StandardTextFieldProps } from '@mui/material'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'

export const AboutInput = (props: StandardTextFieldProps): ReactElement => {
	const { t } = useCustomTranslation('organization:myOrganization')
	return (
		<InputTextFieldForm
			{...props}
			multiline
			minRows={4}
			name="about"
			label={t('generalSettings.about.label')}
			fullWidth
			tooltip={t('generalSettings.about.tooltip')}
		/>
	)
}

import { OrganizationUsers } from '../list/OrganizationUsers'
import { PositionTrackCardEditDialog } from './positionTrack/PositionTrackCardEditDialog'
import UserDetail from './UserDetail'
import { Route } from 'react-router-dom'

enum Path {
	list = '/organization-users',
	details = '/organization-users/:userId',
	positions = '/organization-users/:userId/positions/:isSkippable?'
}

const routes = (
	<>
		<Route path={Path.list} exact={true}>
			<OrganizationUsers />
		</Route>
		<Route path={Path.positions}>
			<PositionTrackCardEditDialog />
		</Route>
		<Route path={Path.details}>
			<UserDetail />
		</Route>
	</>
)

export const OrganizationUsersRouter = {
	paths: Path,
	routes
}

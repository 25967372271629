import { useMeQuery } from '@gqlTypes'
import { type i18n, type TOptions } from 'i18next'
import { useTranslation } from 'react-i18next'

interface UseCustomTranslation {
	t: (translationKey: string, options?: TOptions) => string
	i18n: i18n
}

export const useCustomTranslation = (namespace: string = ''): UseCustomTranslation => {
	const { t: translate, i18n } = useTranslation()
	const { data } = useMeQuery({ fetchPolicy: 'cache-only' })
	const t = (translationKey: string, options?: TOptions): string => {
		const separator = namespace.endsWith(':') || namespace === '' ? '' : '.'
		const customKey = data?.me?.organization.customKey ?? ''
		const [domain, path] = translationKey.split(':')

		const classicKey = `${namespace}${separator}${translationKey}`
		const domainKey =
			path != null
				? `customTranslation:${customKey}.${domain}.${path}`
				: `customTranslation:${customKey}.translation.${domain}`

		const customTranslationKey =
			namespace === '' ? domainKey : `customTranslation:${customKey}.${namespace.split(':').join('.')}.${domain}`
		const key = customKey === '' || !i18n.exists(customTranslationKey) ? classicKey : customTranslationKey
		return translate(key, options)
	}

	return { t, i18n }
}

import { type ReactElement, useState } from 'react'
import { type UserPositionListNodeFragment } from '@gqlTypes'
import { PositionCard } from '@features/lists/positions/PositionCard'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useRole } from '@shared/hooks/useRole'
import { PublicTitle } from './PublicTitle'
import { CurrentManager } from '@domains/employees/list/CurrentManager'
import { CommunityMembersCardEditDialog } from './CommunityMembersCardEditDialog'
import { CommunityMembersRouter } from '../CommunityMembers.router'

interface Props {
	position: UserPositionListNodeFragment
	isSelected?: boolean
	selectRow?: () => void
}

export const CommunityMemberCard = ({ position, isSelected, selectRow }: Props): ReactElement => {
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useCustomTranslation()
	const { screens } = useRole()

	return (
		<PositionCard
			position={position}
			isSelected={isSelected}
			selectRow={selectRow}
			dialogComponent={
				<CommunityMembersCardEditDialog
					position={position}
					handleClose={() => {
						setIsOpen(false)
					}}
					isOpen={isOpen}
				/>
			}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			linkLabel={t('communityMembers:columns.link')}
			detailRoute={CommunityMembersRouter.paths.details}
			riskTitle={t('risk:modal.title.student')}
			isEditable={screens.employees.hasWriteAccess}
			hasFullName
		>
			<PublicTitle
				publicTitle={position.userInfo?.publicTitle ?? ''}
				currentCompany={position.userInfo?.currentCompany ?? ''}
			/>
			<CurrentManager name={position.managerObject?.fullName ?? ''} />
		</PositionCard>
	)
}

import { useGetWebOnboardingQuery, useMeQuery } from '@gqlTypes'
import { OrganizationTitle } from '../components/OrganizationTitle'
import { Onboarding } from '../components/Onboarding'
import { type ReactElement } from 'react'
import { OrganizationTiles } from './OrganizationTiles'
import { MyInterviewsComponent } from '@domains/myInterviews/MyInterviews.component'
import { DefaultLayout } from '@ui/layout/DefaultLayout'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const Home = (): ReactElement | null => {
	const { data, error } = useGetWebOnboardingQuery()
	const { data: meData, loading: meLoading, error: errorMe } = useMeQuery()
	const { t } = useCustomTranslation()
	useDocumentTitle(t('menu.home'))

	if (meLoading) return <LocalLoader />
	if (error != null || errorMe != null) return null

	const organization = meData?.me?.organization
	const firstname = meData?.me?.firstname ?? ''
	const hasWebOnboardingOpen = meData?.me?.hasWebOnboardingOpen ?? false
	const hasActivateEmageMe = meData?.me?.role?.isExternal ?? false
	const webOnboarding = data?.getWebOnboarding
	const { message, video, files } = webOnboarding ?? { message: '', video: '', files: [] }
	const isUserRole = meData?.me?.role?.id === 'user'

	return (
		<DefaultLayout
			topStyle={{ mt: 2, mb: 1 }}
			containerStyle={{ justifyContent: 'flex-start' }}
			title={
				<OrganizationTitle
					title={organization?.name ?? ''}
					picture={organization?.logoUrl ?? ''}
					style={{ marginLeft: 12 }}
				/>
			}
			right={<Onboarding {...{ message, video, files }} isOpen={hasWebOnboardingOpen} firstname={firstname} />}
		>
			<>
				{isUserRole ? <MyInterviewsComponent /> : <OrganizationTiles />}
				{hasActivateEmageMe ? (
					<a
						rel="noreferrer"
						href="https://emage-me.com/activer-emage-me-pour-mon-entreprise"
						target="_blank"
						style={{
							height: 160,
							borderRadius: 25,
							width: 684,
							padding: 20,
							backgroundColor: '#0A2E56',
							margin: 12,
							background: "url('/assets/img/activate-emage-me.png')"
						}}
					/>
				) : null}
			</>
		</DefaultLayout>
	)
}

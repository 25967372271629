import { useState, type ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { MassInterviewsCreationModal } from './MassInterviewsCreationModal'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import { type IntegrationCategory, type TrackType } from '@gqlTypes'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useSnackbar } from 'notistack'
import { IconAction } from '@ui/icon/IconAction'

interface objWithId {
	id: string
}

interface Props<T extends objWithId> {
	dataList: T[]
	trackType: TrackType
	isAllRowsSelected: boolean
	integrationCategory: IntegrationCategory
}

export const MassInterviewsCreation = <T extends objWithId>({
	dataList,
	trackType,
	isAllRowsSelected = false,
	integrationCategory
}: Props<T>): ReactElement<Props<T>> => {
	const { t } = useCustomTranslation()
	const selectedRows = useSelector(({ selectedRows }: { selectedRows: number[] }) => selectedRows)
	const [isOpen, setIsOpen] = useState(false)
	const ids = selectedRows.map((index) => dataList[index]?.id)
	const { enqueueSnackbar } = useSnackbar()

	return (
		<>
			<IconAction
				tooltip={t('modal:massInterviewCreation.tooltip')}
				disabled={selectedRows.length < 1}
				onClick={() => {
					setIsOpen(true)
				}}
			>
				<GroupAddOutlinedIcon />
			</IconAction>
			<MassInterviewsCreationModal
				isOpen={isOpen}
				setOpen={setIsOpen}
				ids={isAllRowsSelected ? [] : ids}
				trackType={trackType}
				onCreation={(variant, message) => {
					enqueueSnackbar(message, { variant })
				}}
				integrationCategory={integrationCategory}
			/>
		</>
	)
}

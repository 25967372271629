import { type ReactElement } from 'react'
import { type StandardTextFieldProps, TextField } from '@mui/material'
import { type Control, Controller, type FieldValues, useFormContext } from 'react-hook-form'
import { getValue } from '#helpers/object.helper'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { AdornmentTooltip } from './adornment/AdornmentTooltip'

interface Props extends StandardTextFieldProps {
	control?: Control
	name: string
	rules?: FieldValues
	label?: string
	tooltip?: string
}

export const InputTextFieldForm = ({
	control,
	name,
	label = '',
	rules = {},
	tooltip = '',
	...textFieldProps
}: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const { errors } = useFormContext()
	const error = getValue(errors, name)
	let errorMessage = error?.message ?? ''
	if (error?.type === 'required') errorMessage = t('form:errors.required')

	const isRequiredLabel = rules?.required != null && label !== ''
	const computedLabel = isRequiredLabel ? `${label} *` : label

	return (
		<Controller
			{...{ control, rules, name }}
			render={({ onChange, value }) => (
				<TextField
					name={name} // name is needed for refscroll
					error={errorMessage !== ''}
					helperText={errorMessage}
					value={value}
					onChange={(e) => {
						onChange(textFieldProps.type === 'number' ? Number(e.target.value) : e.target.value)
					}}
					{...textFieldProps}
					label={computedLabel}
					InputProps={{
						...textFieldProps.InputProps,
						endAdornment: (
							<>
								{textFieldProps.InputProps?.endAdornment}
								<AdornmentTooltip text={tooltip} />
							</>
						)
					}}
				/>
			)}
		/>
	)
}

import { type ReactElement } from 'react'
import { MyOrganizationLayout, TabId } from './MyOrganizationLayout'
import { InterviewTemplateList } from '@domains/interviewTemplates/InterviewTemplateList.page'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'

export const InterviewTemplateConfiguration = (): ReactElement => {
	const { t } = useCustomTranslation()
	useDocumentTitle(t('organization:myOrganization.menu.interviewTemplates'))

	return (
		<MyOrganizationLayout tab={TabId.InterviewTemplates}>
			<InterviewTemplateList customFilterName="interview-template" />
		</MyOrganizationLayout>
	)
}

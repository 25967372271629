import { IconButton, Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { ExternalLink } from '@ui/links/ExternalLink'
import { generateRoute } from '../../router/routes'
import { useParams } from 'react-router-dom'

interface Props {
	attachment: {
		fileUrl: string
		fileName: string
	}
	hasDeleteButton: boolean
	onDelete?: (fileName: string) => void
}

export const Attachment = ({ hasDeleteButton, attachment, onDelete = () => {} }: Props): ReactElement => {
	const { interviewId } = useParams<{ interviewId: string }>()
	const { fileUrl, fileName } = attachment

	const attachmentUrl = fileUrl.includes('.pdf')
		? generateRoute('/pdf/:encodedFilePath', {
				interviewId,
				encodedFilePath: encodeURIComponent(btoa(fileUrl))
			})
		: fileUrl

	return (
		<Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
			<ExternalLink url={attachmentUrl}>
				<Stack sx={{ flexDirection: 'row' }}>
					<DescriptionOutlinedIcon sx={{ color: '#0A2E56', mr: 2 }} />
					<Typography sx={{ color: '#0A2E56' }}>{fileName}</Typography>
				</Stack>
			</ExternalLink>
			{hasDeleteButton ? (
				<IconButton
					onClick={() => {
						onDelete(fileName)
					}}
				>
					<DeleteOutlineOutlinedIcon />
				</IconButton>
			) : null}
		</Stack>
	)
}

import { useEffect, type ReactElement } from 'react'
import { type PutUsersReport } from '@gqlTypes'
import { DialogSection } from '@shared/components/modal/dialogSection'
import { UserImportResultCount } from './UserImportResultCount'
import { Alert, Stack, Typography } from '@mui/material'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { FormatErrorAlert } from './UserImportResult/FormatErrorAlert'
import { ColumnRequiredAlert } from './UserImportResult/ColumnRequiredAlert'
import { FieldErrorAlert } from './UserImportResult/FieldErrorAlert'
import { ColumnFoundAlert } from './UserImportResult/ColumnFoundAlert'
import { storage } from '@infrastructure/firebase/firebase.helper'

interface Props {
	report?: PutUsersReport
}

export const UserImportResult = ({ report }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	if (report == null) return null

	const {
		userCounts,
		integrationCounts,
		columnReport,
		userReport: { fieldErrorCounts },
		reportUrl = ''
	} = report

	const { hasFormatError } = columnReport

	useEffect(() => {
		if (reportUrl === '' || hasFormatError || columnReport.required.length > 0) return

		const downloadFile = async (): Promise<void> => {
			const downloadUrl: string = await storage().ref(reportUrl).getDownloadURL()
			window.open(downloadUrl, '_blank')
		}

		void downloadFile()
	}, [reportUrl])

	if (hasFormatError) return <FormatErrorAlert configuredColumn={columnReport.configured} />
	if (columnReport.required.length > 0) return <ColumnRequiredAlert columnRequired={columnReport.required} />

	return (
		<Stack sx={{ gap: 2 }}>
			<ColumnFoundAlert
				columnFound={columnReport.found}
				columnNotFound={columnReport.notFound}
				configuredColumn={columnReport.configured}
			/>
			<FieldErrorAlert fieldErrorCounts={fieldErrorCounts} />
			<Stack direction="row" sx={{ justifyContent: 'space-around' }}>
				<Stack>
					<Typography variant="h6">
						<b>{t('modal:userImport.userCounts.title')}</b>
					</Typography>
					<UserImportResultCount translationKey="userCounts.createdCount" count={userCounts.createdCount} />
					<UserImportResultCount translationKey="userCounts.updatedCount" count={userCounts.updatedCount} />
					<UserImportResultCount translationKey="userCounts.upToDateCount" count={userCounts.upToDateCount} />
					<UserImportResultCount
						color="warning.main"
						translationKey="userCounts.errorCount"
						count={userCounts.errorCount}
					/>
				</Stack>
				<Stack>
					<Typography variant="h6">
						<b>{t('modal:userImport.integrationCounts.title')}</b>
					</Typography>
					<UserImportResultCount
						translationKey="integrationCounts.createdCount"
						count={integrationCounts.createdCount}
					/>
					<UserImportResultCount
						translationKey="integrationCounts.updatedCount"
						count={integrationCounts.updatedCount}
					/>
					<UserImportResultCount
						translationKey="integrationCounts.upToDateCount"
						count={integrationCounts.upToDateCount}
					/>
					<UserImportResultCount
						color="warning.main"
						translationKey="integrationCounts.errorCount"
						count={integrationCounts.errorCount}
					/>
				</Stack>
			</Stack>
			{reportUrl !== '' ? (
				<DialogSection>
					<Alert severity="info">{t('modal:userImport.reportDownloaded')}</Alert>
				</DialogSection>
			) : null}
		</Stack>
	)
}

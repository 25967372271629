import { Stack, Alert } from '@mui/material'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const OrganizationAbout = (): ReactElement => {
	const { t } = useTranslation()
	return (
		<Stack gap={2}>
			<InputTextFieldForm
				multiline
				rows={3}
				name="organizationAbout"
				label={t('organizationCreation:organization.about')}
			/>
			<Alert severity="info">{t('organizationCreation:organization.aboutDescription')}</Alert>
		</Stack>
	)
}

import { type ReactElement } from 'react'
import { Alert, AlertTitle, Button, Stack } from '@mui/material'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	configuredColumn: string[]
}

export const FormatErrorAlert = ({ configuredColumn }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()

	const downloadTemplate = (): void => {
		console.log('configuredColumn', configuredColumn)
		const blob = new Blob([configuredColumn.join(';')], { type: 'data:text/csv;charset=utf-8' })
		const href = URL.createObjectURL(blob)
		const link = document.createElement('a')
		link.href = href
		link.download = 'user_import_template.csv'
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
		URL.revokeObjectURL(href)
	}

	return (
		<Stack sx={{ gap: 2 }}>
			<Alert severity="error">
				<AlertTitle>
					<b>{t('modal:userImport.formatError.title')}</b>
				</AlertTitle>
				{t('modal:userImport.formatError.message')}
			</Alert>
			<Alert
				severity="info"
				action={
					<Button color="inherit" size="small" onClick={downloadTemplate}>
						{t('modal:userImport.formatError.downloadTemplate')}
					</Button>
				}
			>
				<AlertTitle>
					<b>{t('modal:userImport.formatError.template')}</b>
				</AlertTitle>
			</Alert>
		</Stack>
	)
}

import { Controller } from 'react-hook-form'
import { TextField, type TextFieldProps } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { type ReactElement } from 'react'

interface Props {
	name: string
	label: string
	textFieldProps?: TextFieldProps
}

export const DateField = ({ name, label, textFieldProps }: Props): ReactElement => {
	return (
		<Controller
			name={name}
			render={({ onChange, value }) => {
				return (
					<MobileDatePicker
						renderInput={(params) => <TextField size={'small'} {...params} {...textFieldProps} />}
						value={value}
						onChange={onChange}
						label={label}
					/>
				)
			}}
		/>
	)
}

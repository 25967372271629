import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type ApolloQueryResult } from '@apollo/client'
import { Mode } from '@application/dashboard/ModeToggle'
import { Filters } from '@features/lists/components/Filters'
import BusinessUnitFilter from '@domains/businessUnit/BusinessUnitFilter'
import { CheckboxInput } from '@features/lists/components/Filters/CheckboxInput'
import { CommunityLabelsFilter } from '@features/lists/components/Filters/CommunityLabelsFilter'
import { Fullname } from '@features/lists/components/Filters/Fullname'
import { InterviewTypesToCreate } from '@domains/integrations/InterviewTypeStateFilter'
import LocationFilter from '@domains/location/LocationsFilter'
import ManagersFilter from '@domains/manager/ManagersFilter'
import PositionStatusFilter from '@domains/positionStatus/PositionStatusFilter'
import { PromotionFilter } from '@domains/promotion/PromotionFilter'
import RiskFilter from '@features/lists/components/Filters/RiskFilter'
import { TextFilter } from '@features/lists/components/Filters/TextFilter'
import { TrackPositionFilter } from '@features/lists/components/Filters/TrackPositionFilter'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import { type ReactElement } from 'react'

interface Props {
	refetch: () => Promise<ApolloQueryResult<unknown>>
	mode?: Mode
}

export const StudentsFilters = ({ refetch, mode = Mode.List }: Props): ReactElement => {
	const isList = mode === Mode.List
	const { t } = useCustomTranslation()
	return (
		<Filters refetch={refetch}>
			<FiltersContent>
				<Fullname />
				<TextFilter keyName="userInfo.externalId" label={t('lists:filter.externalId')} />
				<TrackPositionFilter keyName="orgaPosition.id" keyLabel="schoolTrack" hasNoneValue={isList} />
				<LocationFilter
					label={t('lists:filter.campus.label')}
					allValueLabel={t('lists:filter.campus.allLabel')}
					hasNoneValue={isList}
				/>
				<ManagersFilter keyLabel="referents" hasNoneValue={isList} />
				<ManagersFilter keyName="externalAssessorId" keyLabel="tutors" hasNoneValue={isList} />
				<BusinessUnitFilter isSchool hasNoneValue={isList} />
				<PromotionFilter hasNoneValue={isList} />
				<PositionStatusFilter hasNoneValue={isList} />
				<RiskFilter hasNoneValue={isList} />
				<TextFilter keyName="userInfo.currentCompanyLowercase" label={t('lists:filter.currentCompany')} />
				{isList ? <CheckboxInput keyName="isArchived" translationKey="lists:filter.archived" /> : null}
				<CheckboxInput keyName="isActive" translationKey="lists:filter.isActive" />
				<CheckboxInput
					keyName="isExternalAssessorValidated"
					translationKey="lists:filter.isExternalAssessorValidated"
				/>
				<CheckboxInput keyName="isOnboardingCompleted" translationKey="lists:filter.isOnboardingCompleted" />
				<InterviewTypesToCreate />
				<CommunityLabelsFilter />
			</FiltersContent>
		</Filters>
	)
}

import { type AutocompleteSelectProps } from '@ui/form/AutocompleteSelect'
import { useGetManagersQuery } from '@gqlTypes'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { UsersAutocompleteSelect } from '@domains/user/components/UsersAutocompleteSelect'

interface Props extends Partial<AutocompleteSelectProps> {}

export const ManagerSelect = (props: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const { data } = useGetManagersQuery()
	const managers = data?.getManagers ?? []
	return (
		<UsersAutocompleteSelect
			users={managers}
			name={'manager'}
			noneValueLabel={t('organizationUsers:details.cards.tracks.form.employee.manager.none')}
			label={t('organizationUsers:details.cards.tracks.form.employee.manager.label')}
			{...props}
		/>
	)
}

import { AutocompleteSelect, type AutocompleteSelectProps } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'
import { useOrganization } from '@domains/organization/useOrganization'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props extends Partial<AutocompleteSelectProps> {}

export const PositionStatusSelect = (props: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { organization } = useOrganization()
	const positionStatus = organization?.positionStatus ?? []
	const availablePositionStatus = positionStatus.map((status) => ({
		value: status.id,
		label: status.label
	}))

	return (
		<AutocompleteSelect
			name="status"
			noneValueLabel={t('organizationUsers:details.cards.tracks.form.positionStatus.none')}
			items={availablePositionStatus}
			label={t('organizationUsers:details.cards.tracks.form.positionStatus.label')}
			{...props}
		/>
	)
}

import { useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter, {
	type AutoCompleteFilterProps
} from '../../features/lists/components/Filters/AutoCompleteFilter'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props extends AutoCompleteFilterProps {
	isSchool?: boolean
}

export default function BusinessUnitFilter({ isSchool = false, ...props }: Props): JSX.Element {
	const { t } = useCustomTranslation()
	const translationKey = isSchool ? 'school' : 'businessUnit'

	const { data, loading } = useMeQuery()
	if (loading || data?.me == null) return <></>
	const businessUnit = data.me.businessUnit
	const availableBusinessUnits = data.me.organization?.businessUnits.map((bu: string) => ({ value: bu, label: bu }))
	const hasRestriction = businessUnit != null && businessUnit !== ''

	if (hasRestriction) return <></>

	return (
		<InputFilter keyName="businessUnit" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter
				{...props}
				options={availableBusinessUnits}
				label={t(`lists:filter.${translationKey}.label`)}
				allValueLabel={t(`lists:filter.${translationKey}.allLabel`)}
				width="100%"
			/>
		</InputFilter>
	)
}

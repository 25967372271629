import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useMeQuery } from '@gqlTypes'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'

export const InterviewTypesInput = (): ReactElement => {
	const { t } = useCustomTranslation()
	const { data } = useMeQuery()
	const items = (data?.me?.organization.interviewTypes ?? []).map((item) => ({ value: item.id, label: item.label }))
	const name = 'interviewTypeIds'

	return (
		<AutocompleteSelect
			label={t('organization:myOrganization.generalSettings.interviewTypes.label')}
			name={name}
			items={items}
			disableClearable
			isMultiple
		/>
	)
}

import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { useGetMacroSoftSKillsQuery, useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const SoftskillFilter = (): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { data: meData, loading: meLoading } = useMeQuery()
	const { data, loading: skillLoading } = useGetMacroSoftSKillsQuery()
	const items = (data?.getMacroSoftSKills ?? []).map((item) => ({
		value: item.id ?? '',
		label: item.label ?? ''
	}))

	const isLoading = meLoading || skillLoading
	const hasSoftskills = meData?.me?.organization?.matchingRestrictions?.hasSoftskills ?? false

	if (items.length <= 1 || isLoading || !hasSoftskills) return null

	return (
		<InputFilter keyName={'topSoftSkillIds'} operation={filterOperation.CONTAIN_ANY}>
			<AutoCompleteFilter
				options={items}
				label={t('offers:resumeLibrary.filters.softskills.label')}
				allValueLabel={t('offers:resumeLibrary.filters.softskills.allLabel')}
				width="100%"
				multiple
			/>
		</InputFilter>
	)
}

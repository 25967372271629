import { type ReactElement, useState } from 'react'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { EmployeesFilterBar } from './EmployeesFilterBar'
import useCheckRoleSecurity from '@shared/hooks/useRoleSecurity'
import { ScreenId, useGetEmployeesListQuery } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import { Container, Typography } from '@mui/material'
import { CardList } from '@features/lists/components/CardList'
import { EmployeeCard } from './EmployeeCard'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { searchQuery } from '@features/lists/components/Search'
import { useOrganization } from '@domains/organization/useOrganization'
import { Mode, ModeToggle } from '@application/dashboard/ModeToggle'
import { EmployeesRouter } from '../Employees.router'
import { useHistory } from 'react-router-dom'
import { generateRoute } from '../../../router/routes'

export const EmployeesList = (): ReactElement => {
	const { t } = useCustomTranslation()
	const history = useHistory()
	useDocumentTitle(t('employees:title'))
	useCheckRoleSecurity(ScreenId.Employees)
	const { filters } = useFilters()

	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)
	const { organizationId } = useOrganization()

	const variables = { organizationId, limit: 12, filters, searchQuery: searchQuery() }
	const { loading, data, refetch, fetchMore } = useGetEmployeesListQuery({
		variables
	})
	useDefaultFilter({ refetch })

	const { nodes = [], cursor = '', hasNextPage = false, count = 0 } = data?.getEmployeesList ?? {}

	return (
		<Container maxWidth="xl">
			<CardList
				renderItem={(item, isSelected, selectRow) => (
					<EmployeeCard key={item.id} position={item} isSelected={isSelected} selectRow={selectRow} />
				)}
				emptyComponent={<Typography sx={{ fontSize: 24 }}>{t('employees:dataGrid.empty')}</Typography>}
				isAllRowsSelected={isAllRowsSelected}
				setIsAllRowsSelected={setIsAllRowsSelected}
				dataList={nodes}
				isLoading={loading}
				fetchMore={fetchMore}
				cursor={cursor}
				hasNextPage={hasNextPage}
				searchPlaceHolder={t('searchBar:placeHolder.employees')}
				title={t('employees:title', { count })}
				toolbar={
					<EmployeesFilterBar isAllRowsSelected={isAllRowsSelected} refetch={refetch} dataList={nodes} count={count} />
				}
				headerLeftComponent={
					<ModeToggle
						mode={Mode.List}
						onToggle={() => {
							history.push(generateRoute(EmployeesRouter.paths.dashboard))
						}}
					/>
				}
				refetch={refetch}
				hasSearch
			/>
		</Container>
	)
}

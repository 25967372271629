import { useCustomTranslation } from '#translations/useCustomTranslation'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'

export const WebsiteInput = (): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<InputTextFieldForm
			name="website"
			label={t('organization:myOrganization.generalSettings.website.label')}
			rules={{
				required: t('form:errors.required'),
				pattern: {
					value: /https?:\/\/[\w\-+&@#/%?=~_|!:,.;]+[\w/]/g,
					message: t('form:errors.urlFormat')
				}
			}}
		/>
	)
}

import { useCustomTranslation } from '#translations/useCustomTranslation'
import { UsersAutocompleteSelect } from '@domains/user/components/UsersAutocompleteSelect'
import { useGetAssessorsQuery } from '@gqlTypes'
import { type ReactElement } from 'react'

export const AssessorField = (): ReactElement => {
	const { t } = useCustomTranslation()
	const { data } = useGetAssessorsQuery()
	const assessors = data?.getAssessors ?? []
	const rules = { required: t('drawer:interview.required') }

	return (
		<UsersAutocompleteSelect users={assessors} name={'juryId'} rules={rules} label={t('drawer:interview.assessor')} />
	)
}

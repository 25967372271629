import { Home } from '../features/home/pages/Home'
import { DiscussionDetailsPage } from '../domains/discussions/details/DiscussionDetailsPage'
import { InterviewDetailPage } from '../domains/interviews/details/InterviewDetailPage'
import { Candidates } from '../domains/candidates/list/Candidates.page'
import { Discussions } from '../domains/discussions/list/Discussions'
import { BrandFeedback } from '../domains/brandFeedbacks/tab/BrandFeedback'
import { CandidatesDetails } from '../domains/candidates/details/CandidatesDetails.page'
import { StudentsDetails } from '../domains/students/details/StudentsDetails'
import { TrackList } from '../domains/tracks/list/TracksList'
import TrackDetail from '../domains/tracks/details/TrackDetail'
import { MobileAuth } from '../features/mobileAuth/pages/MobileAuth'
import { CreatePassword } from '@features/password/pages/CreatePassword'
import { Invitation } from '@features/invitation/pages/Invitation'
import { RequestPasswordPage } from '@features/login/RequestPasswordPage'
import { Logout } from '@application/auth/Logout'
import { InterviewTemplateList } from '@domains/interviewTemplates/InterviewTemplateList.page'
import { InterviewTemplateDetail } from '@domains/interviewTemplates/InterviewTemplateDetail.page'
import { MyInterviews } from '@domains/myInterviews/MyInterviews.page'
import { offersRoutes } from '@domains/offers/OffersRouter'
import { TokenAuth } from '@features/login/TokenAuth'
import { feedbackRequestRoutes } from '@domains/feedbackRequest/FeedbackRequest.router'
import { myProfileRouter } from '@domains/myProfile/myProfile.router'
import { organizationCreationRoutes } from '@domains/organizationCreation/OrganizationCreation.router'
import { FixInterviewPdf } from '@domains/interviews/pdf/fixInterviewPdf.page'
import { RoutePath } from './RoutePath'
import { StudentsRouter } from '@domains/students/Students.router'
import { InterviewsRouter } from '@domains/interviews/Interviews.router'
import { ArticlesRouter } from '@domains/articles/Articles.router'
import { organizationRouter } from '@domains/organization/organization.router'
import { CommunityMembersRouter } from '@domains/communityMembers/CommunityMembers.router'
import { EmployeesRouter } from '@domains/employees/Employees.router'

export const ROUTE_HOME = '/'
export const ROUTE_AUTH = '/auth/:token'
export const ROUTE_MOBILE_AUTH = '/mobileAuth/:interviewId'
export const ROUTE_CREATE_PASSWORD = '/createPassword/:passwordTokenId'
export const ROUTE_REQUEST_PASSWORD = '/requestPassword'
export const ROUTE_INVITATION = '/invitation/:invitationId'
export const ROUTE_CANDIDATES = '/candidates'
export const ROUTE_CANDIDATE_DETAIL = '/candidates/:userOfferId'
export const ROUTE_CANDIDATE_DETAIL_TAB_INFO = '/candidates/:userOfferId/information'
export const ROUTE_CANDIDATE_DETAIL_TAB_PROFILE = '/candidates/:userOfferId/profile'
export const ROUTE_CANDIDATE_DETAIL_TAB_DISCUSSION = '/candidates/:userOfferId/discussion'
export const ROUTE_CANDIDATE_DETAIL_TAB_INTERVIEW = '/candidates/:userOfferId/interview'
export const ROUTE_CANDIDATE_DETAIL_TAB_PROGRESSION = '/candidates/:userOfferId/progression'
export const ROUTE_CANDIDATE_DETAIL_TAB_RETURNS = '/candidates/:userOfferId/returns'
export const ROUTE_CANDIDATE_DETAIL_TAB_ORGA_RETURN = '/candidates/:userOfferId/orgareturn'
export const ROUTE_CANDIDATE_DETAIL_TAB_CAREER = '/candidates/:userOfferId/career'
export const ROUTE_INTERVIEW = '/interview'
export const ROUTE_INTERVIEW_DETAIL_LONG = '/interview/:integrationType/:userIntegrationId/:interviewId'
export const ROUTE_INTERVIEW_DETAIL = '/interview/:interviewId'
export const ROUTE_COLLABORATOR = '/collaborator'
export const ROUTE_DISCUSSIONS = '/discussions'
export const ROUTE_DISCUSSION_DETAIL = '/discussions/:discussionUserId'
export const ROUTE_ACCESS_MANAGEMENT = '/administration'
export const ROUTE_EMPLOYEES = '/employees'
export const ROUTE_EMPLOYEES_DETAIL = '/employees/:userPositionId'
export const ROUTE_EMPLOYEES_DETAIL_TAB_INFO = '/employees/:userPositionId/information'
export const ROUTE_EMPLOYEES_DETAIL_TAB_PROFILE = '/employees/:userPositionId/profile'
export const ROUTE_EMPLOYEES_DETAIL_TAB_DISCUSSION = '/employees/:userPositionId/discussion'
export const ROUTE_EMPLOYEES_DETAIL_TAB_INTERVIEW = '/employees/:userPositionId/interview'
export const ROUTE_EMPLOYEES_DETAIL_TAB_PROGRESSION = '/employees/:userPositionId/progression'
export const ROUTE_EMPLOYEES_DETAIL_TAB_RETURNS = '/employees/:userPositionId/returns'
export const ROUTE_EMPLOYEES_DETAIL_TAB_ORGA_RETURN = '/employees/:userPositionId/orgareturn'
export const ROUTE_EMPLOYEES_DETAIL_TAB_CAREER = '/employees/:userPositionId/career'
export const ROUTE_STUDENTS = '/students'
export const ROUTE_STUDENTS_DETAIL = '/students/:userPositionId'
export const ROUTE_STUDENTS_DETAIL_TAB_INFO = '/students/:userPositionId/information'
export const ROUTE_STUDENTS_DETAIL_TAB_PROFILE = '/students/:userPositionId/profile'
export const ROUTE_STUDENTS_DETAIL_TAB_DISCUSSION = '/students/:userPositionId/discussion'
export const ROUTE_STUDENTS_DETAIL_TAB_INTERVIEW = '/students/:userPositionId/interview'
export const ROUTE_STUDENTS_DETAIL_TAB_PROGRESSION = '/students/:userPositionId/progression'
export const ROUTE_STUDENTS_DETAIL_TAB_RETURNS = '/students/:userPositionId/returns'
export const ROUTE_STUDENTS_DETAIL_TAB_ORGA_RETURN = '/students/:userPositionId/orgareturn'
export const ROUTE_STUDENTS_DETAIL_TAB_CAREER = '/students/:userPositionId/career'
export const ROUTE_COMMUNITY_MEMBERS_TAB_INFO = '/community-members/:userPositionId/information'
export const ROUTE_COMMUNITY_MEMBERS_TAB_PROFILE = '/community-members/:userPositionId/profile'
export const ROUTE_COMMUNITY_MEMBERS_TAB_DISCUSSION = '/community-members/:userPositionId/discussion'
export const ROUTE_COMMUNITY_MEMBERS_TAB_INTERVIEW = '/community-members/:userPositionId/interview'
export const ROUTE_COMMUNITY_MEMBERS_DETAIL_TAB_PROGRESSION = '/community-members/:userPositionId/progression'
export const ROUTE_COMMUNITY_MEMBERS_TAB_RETURNS = '/community-members/:userPositionId/returns'
export const ROUTE_COMMUNITY_MEMBERS_TAB_ORGA_RETURN = '/community-members/:userPositionId/orgareturn'
export const ROUTE_COMMUNITY_MEMBERS_TAB_CAREER = '/community-members/:userPositionId/career'
export const ROUTE_TRACKS_OFFER = '/tracks/organization-offer'
export const ROUTE_TRACKS_OFFER_DETAIL = '/tracks/organization-offer/:organizationIntegrationId'
export const ROUTE_TRACKS_POSITION = '/tracks/organization-position'
export const ROUTE_TRACKS_POSITION_DETAIL = '/tracks/organization-position/:organizationIntegrationId'
export const ROUTE_ORGA_RETURNS = '/orgareturns'
export const ROUTE_LOGOUT = '/logout'
export const ROUTE_INTERVIEW_TEMPLATE_LIST = '/interview-template'
export const ROUTE_INTERVIEW_TEMPLATE_DETAIL = '/interview-template/:interviewTemplateId'
export const ROUTE_MY_INTERVIEWS = '/my-interviews'
export const ROUTE_ARTICLE = '/article'

export const getDiscussionPathName = (discussionId = ':id'): string => {
	return ROUTE_DISCUSSIONS + '/' + discussionId
}

export const generateRoute = (path: string, params?: Record<string, string>): string => {
	let generatedPath = path
	if (params != null) {
		Object.keys(params).forEach((paramKey) => {
			const paramName = paramKey != null && paramKey.startsWith(':') ? paramKey : ':' + paramKey
			generatedPath = replaceParameter(generatedPath, paramName, params[paramKey])
		})
	}
	return generatedPath.replace(/\/:\w+\??/g, '')
}

const replaceParameter = (path: string, paramName: string, paramValue: string): string => {
	const pathWithoutOptionalParam = path.replace(paramName + '?', paramValue)
	return pathWithoutOptionalParam.replace(paramName, paramValue)
}

/**
 * Route description
 *
 * path : String : route path cf Route react-dom-router for syntax
 * notExact : true|false : exact path or not if not present the route will be exact
 * role : String(role value) : value from ./router/roles : anonymous if not set
 * children : any : Children to render
 */
export const routes = [
	{
		path: ROUTE_HOME,
		label: 'menu.home',
		children: <Home />
	},
	{
		path: ROUTE_AUTH,
		label: 'menu.home',
		public: true,
		children: <TokenAuth />
	},
	{
		path: ROUTE_MOBILE_AUTH,
		label: 'menu.home',
		public: true,
		children: <MobileAuth />
	},
	{
		path: ROUTE_CREATE_PASSWORD,
		label: 'menu.home',
		public: true,
		children: <CreatePassword />
	},
	{
		path: ROUTE_REQUEST_PASSWORD,
		label: 'menu.home',
		public: true,
		children: <RequestPasswordPage />
	},
	{
		path: ROUTE_INVITATION,
		label: 'menu.home',
		public: true,
		children: <Invitation />
	},
	{
		path: ROUTE_CANDIDATES,
		label: 'menu.candidates',
		children: <Candidates />
	},
	{
		path: ROUTE_CANDIDATE_DETAIL,
		label: 'screens.candidate',
		children: <CandidatesDetails />,
		notExact: true
	},
	...InterviewsRouter.routes,
	{
		path: ROUTE_INTERVIEW_DETAIL_LONG,
		children: <InterviewDetailPage />
	},
	{
		path: ROUTE_INTERVIEW_DETAIL,
		children: <InterviewDetailPage />
	},
	{
		path: ROUTE_DISCUSSIONS,
		label: 'menu.chat',
		children: <Discussions />
	},
	{
		path: ROUTE_DISCUSSION_DETAIL,
		children: <DiscussionDetailsPage />,
		notExact: true
	},
	...CommunityMembersRouter.routes,
	...EmployeesRouter.routes,
	...StudentsRouter.routes,
	{
		path: ROUTE_STUDENTS_DETAIL,
		children: <StudentsDetails />,
		notExact: true
	},
	{
		path: ROUTE_TRACKS_OFFER,
		children: <TrackList />
	},
	{
		path: ROUTE_TRACKS_POSITION,
		children: <TrackList />
	},
	{
		path: ROUTE_TRACKS_OFFER_DETAIL,
		children: <TrackDetail />
	},
	{
		path: ROUTE_TRACKS_POSITION_DETAIL,
		children: <TrackDetail />
	},
	{
		path: ROUTE_ORGA_RETURNS,
		children: <BrandFeedback />
	},
	{
		path: ROUTE_LOGOUT,
		public: true,
		children: <Logout />
	},
	{
		path: ROUTE_INTERVIEW_TEMPLATE_LIST,
		children: <InterviewTemplateList />
	},
	{
		path: ROUTE_INTERVIEW_TEMPLATE_DETAIL,
		children: <InterviewTemplateDetail />
	},
	{
		path: ROUTE_MY_INTERVIEWS,
		children: <MyInterviews />
	},
	{
		path: RoutePath.fixInterviewPdf,
		children: <FixInterviewPdf />
	},
	...myProfileRouter.routes,
	...offersRoutes,
	...feedbackRequestRoutes,
	...organizationCreationRoutes,
	...ArticlesRouter.routes,
	...organizationRouter.routes
]

import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Button, type ButtonProps } from '@mui/material'
import { type ReactElement } from 'react'

export const CancelFormButton = (buttonProps: ButtonProps): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<Button variant="contained" {...buttonProps}>
			{t('form:button.cancel.label')}
		</Button>
	)
}

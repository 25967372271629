import { useCustomTranslation } from '#translations/useCustomTranslation'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'

export const PublicTitleInput = (): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<InputTextFieldForm
			name="publicTitle"
			required
			label={t('user:input.publicTitle.label')}
			tooltip={t('user:input.publicTitle.tooltip')}
		/>
	)
}

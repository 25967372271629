import { type ReactElement } from 'react'
import Button from '@mui/material/Button'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { OrganizationUsersRouter } from '../OrganizationUsers.router'
import { useHistory } from 'react-router-dom'
import { generateRoute } from '../../../../router/routes'
import { Box, Card, List, ListItem, ListItemButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useGetUserPositionsQuery } from '@gqlTypes'
import { Header } from '@ui/layout/Header'
interface Props {
	userId: string
}

export const OrganizationUserDetailsTrackCard = ({ userId }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const history = useHistory()
	const { data } = useGetUserPositionsQuery({ variables: { userId } })
	const positions = data?.getUser?.userPositions ?? []

	const openSubscribePositionModal = (): void => {
		history.push(generateRoute(OrganizationUsersRouter.paths.positions, { userId }))
	}

	if (userId === 'new') return null

	return (
		<Box>
			<Header
				title={t('organizationUsers:details.cards.tracks.title', { count: positions.length })}
				action={
					<Button onClick={openSubscribePositionModal} startIcon={<AddIcon />}>
						{t('organizationUsers:details.cards.tracks.openForm')}
					</Button>
				}
			/>
			{positions.length > 0 ? (
				<Card>
					<List>
						{positions.map(({ id, trackLabel, isArchived }) => (
							<ListItem key={id}>
								<ListItemButton disabled={isArchived}>{trackLabel}</ListItemButton>
							</ListItem>
						))}
					</List>
				</Card>
			) : null}
		</Box>
	)
}

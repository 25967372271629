import { DashboardSection } from '@application/dashboard/DashboardSection'
import { Layout } from '@application/dashboard/Layout'
import { Mode, ModeToggle } from '@application/dashboard/ModeToggle'
import { type ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Stack } from '@mui/material'
import { useFilters } from '@shared/hooks/useFilters'
import { CommunityMembersRouter } from '../CommunityMembers.router'
import { CommunityMembersFilters } from '../CommunityMembersFilter'
import { useGetCommunityMembersDashboardQuery } from '@gqlTypes'

export const CommunityMembersDashboard = (): ReactElement | null => {
	const { filters } = useFilters()
	const { data, loading, refetch } = useGetCommunityMembersDashboardQuery({ variables: { filters } })
	const history = useHistory()
	const { t } = useCustomTranslation()

	if (Boolean(loading) || data == null) return null
	const sections = data.getCommunityMembersDashboard.sections ?? []
	const total = sections[0]?.tiles[0]?.indicators[0]?.value ?? 0
	return (
		<Layout
			title={t('communityMembers:title', { count: Number(total) })}
			toolbar={<CommunityMembersFilters mode={Mode.Dashboard} refetch={refetch} />}
			headerLeftComponent={
				<ModeToggle
					mode={Mode.Dashboard}
					onToggle={() => {
						history.push(CommunityMembersRouter.paths.list)
					}}
				/>
			}
		>
			<Stack spacing={3}>
				{sections.map((section) => (
					<DashboardSection label={section.label} tiles={section.tiles} key={section.id} />
				))}
			</Stack>
		</Layout>
	)
}

import { Box, Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Attachment } from '@ui/fileUpload/Attachment'
import { type GetInterviewDetailsQuery } from '@gqlTypes'

interface Props {
	connectedUserId: string
	interview: GetInterviewDetailsQuery['getInterview']
	setFileNameChosenToBeDeleted: (fileName: string) => void
}

export const InterviewAttachments = ({
	interview,
	connectedUserId,
	setFileNameChosenToBeDeleted
}: Props): ReactElement | null => {
	const { isArchived, isSignedByAssessed, isSignedByAssessor } = interview
	const attachments = interview.attachmentsObject ?? []
	const { t } = useCustomTranslation('translation:attachment')

	if (attachments.length === 0) return null
	return (
		<Box>
			<Typography>{t('interview.title')}</Typography>
			<Stack spacing={1}>
				{attachments.map(({ fileUrl, fileName, authorId }) => (
					<Attachment
						key={fileUrl}
						{...{
							attachment: { fileUrl, fileName },
							onDelete: () => {
								setFileNameChosenToBeDeleted(fileName)
							},
							hasDeleteButton: authorId === connectedUserId && !isArchived && !isSignedByAssessor && !isSignedByAssessed
						}}
					/>
				))}
			</Stack>
		</Box>
	)
}

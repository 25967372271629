import { type AutocompleteSelectProps } from '@ui/form/AutocompleteSelect'
import { useGetManagersQuery } from '@gqlTypes'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { UsersAutocompleteSelect } from '@domains/user/components/UsersAutocompleteSelect'

interface Props extends Partial<AutocompleteSelectProps> {}

export const TutorSelect = (props: Props): ReactElement => {
	const { setValue } = useFormContext()
	const { t } = useCustomTranslation()
	const { data } = useGetManagersQuery()
	const managers = data?.getManagers ?? []
	return (
		<UsersAutocompleteSelect
			users={managers}
			name={'externalAssessorId'}
			noneValueLabel={t('organizationUsers:details.cards.tracks.form.tutor.none')}
			onValueChange={(value) => {
				setValue('isExternalAssessorValidated', true)
			}}
			{...props}
		/>
	)
}

import { useCustomTranslation } from '#translations/useCustomTranslation'
import { generateRoute } from '../../../router/routes'
import { Fullname } from '@features/lists/components/Filters/Fullname'
import { TextFilter } from '@features/lists/components/Filters/TextFilter'
import { filterOperation } from '@ui/filter/FilterForm'
import LocationFilter from '@domains/location/LocationsFilter'
import BusinessUnitFilter from '@domains/businessUnit/BusinessUnitFilter'
import { RoleFilter } from '@features/lists/components/Filters/RoleFilter'
import { type FilterInput, UserExportDocument, type GetOrganizationUserListQuery } from '@gqlTypes'
import { UserImport } from '../import/UserImport'
import { LeftBar } from '@features/lists/components/LeftBar'
import { Filters } from '@features/lists/components/Filters'
import { CheckboxInput } from '@features/lists/components/Filters/CheckboxInput'
import AddIcon from '@mui/icons-material/Add'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import { IconAction } from '@ui/icon/IconAction'
import { ExportGQL } from '@features/modals/components/actions/ExportGQL'
import { OrganizationUsersRouter } from '../details/OrganizationUsers.router'

interface Props {
	isAllRowsSelected: boolean
	refetch: ({ filters }: { filters: FilterInput[] }) => void
	dataList: NonNullable<NonNullable<GetOrganizationUserListQuery['getOrganizationUserList']>['nodes']>
}

export default function ListBar({ isAllRowsSelected, refetch, dataList }: Props): JSX.Element | null {
	const { t } = useCustomTranslation()

	return (
		<LeftBar>
			<Filters refetch={refetch}>
				<FiltersContent>
					<RoleFilter />
					<LocationFilter isBasedOnOrgaType />
					<BusinessUnitFilter isSchool />
					<Fullname firstNameKey="firstnameLowercase" lastNameKey="lastnameLowercase" />
					<TextFilter keyName="email" operation={filterOperation.EQUAL} label={t('lists:filter.email')} />
					<CheckboxInput keyName="active" translationKey="lists:filter.archived" isReversedValue />
				</FiltersContent>
			</Filters>
			<IconAction
				tooltip={t('organizationUsers:list.bar.button.new')}
				to={generateRoute(OrganizationUsersRouter.paths.details, { userId: 'new' })}
			>
				<AddIcon />
			</IconAction>
			<UserImport />
			<ExportGQL isAllRowsSelected={isAllRowsSelected} dataList={dataList} QUERY={UserExportDocument} />
		</LeftBar>
	)
}

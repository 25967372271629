import InputTextField from '@ui/form/inputTextField'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type FieldValues, type Control, type FieldError } from 'react-hook-form'

interface Props {
	control: Control
	defaultValue?: string
	name: string
	required?: boolean
	error?: FieldError
	rules?: FieldValues
}

export default function TextField({
	control,
	defaultValue,
	name,
	required = false,
	error,
	rules = {}
}: Props): JSX.Element {
	const { t } = useCustomTranslation()
	const title = t(`organizationUsers:details.formFields.${name}.title`)
	const tooltip = t(`organizationUsers:details.formFields.${name}.tooltip`)
	return (
		<TableLayoutRow title={title} tooltip={tooltip}>
			<InputTextField
				control={control}
				name={name}
				defaultValue={defaultValue}
				required={required}
				error={error}
				rules={rules}
			/>
		</TableLayoutRow>
	)
}

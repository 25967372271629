import { useCustomTranslation } from '#translations/useCustomTranslation'
import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { type ReactElement } from 'react'

interface Props {
	onChange?: (newValue: string[]) => void
	value?: string[] | string
	macroHardSkillItems: { value: string; label: string }[]
	microHardSkillItems: { value: string; label: string }[]
	isLoading: boolean
}

export const TopHardskillFilters = ({
	onChange = () => {},
	value = [],
	macroHardSkillItems,
	microHardSkillItems,
	isLoading
}: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const isEmptyValue = typeof value === 'string' // handle case of inputFilter returning 'empty'
	const macroSelectedList = isEmptyValue
		? []
		: value.filter((item) => macroHardSkillItems.map(({ value }) => value).includes(item))
	const microSelectedList = isEmptyValue
		? []
		: value.filter((item) => microHardSkillItems.map(({ value }) => value).includes(item))

	const handleOnchangeMacro = (newValue: string[]): void => {
		onChange([...microSelectedList, ...newValue])
	}
	const handleOnchangeMicro = (newValue: string[]): void => {
		onChange([...macroSelectedList, ...newValue])
	}

	return (
		<>
			<AutoCompleteFilter
				options={macroHardSkillItems}
				label={t('offers:resumeLibrary.filters.macroHardSkills.label')}
				allValueLabel={t('offers:resumeLibrary.filters.macroHardSkills.allLabel')}
				width="100%"
				multiple
				onChange={(value) => {
					handleOnchangeMacro(value as string[])
				}}
				value={macroSelectedList}
				isLoading={isLoading}
			/>
			<AutoCompleteFilter
				options={microHardSkillItems}
				label={t('offers:resumeLibrary.filters.microHardSkills.label')}
				allValueLabel={t('offers:resumeLibrary.filters.microHardSkills.allLabel')}
				width="100%"
				multiple
				onChange={(value) => {
					handleOnchangeMicro(value as string[])
				}}
				value={microSelectedList}
				isLoading={isLoading}
			/>
		</>
	)
}

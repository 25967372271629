import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type ApolloQueryResult } from '@apollo/client'
import { Mode } from '@application/dashboard/ModeToggle'
import { Filters } from '@features/lists/components/Filters'
import BusinessUnitFilter from '@domains/businessUnit/BusinessUnitFilter'
import { CheckboxInput } from '@features/lists/components/Filters/CheckboxInput'
import { Fullname } from '@features/lists/components/Filters/Fullname'
import ManagersFilter from '@domains/manager/ManagersFilter'
import { TextFilter } from '@features/lists/components/Filters/TextFilter'
import { TrackPositionFilter } from '@features/lists/components/Filters/TrackPositionFilter'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import { type ReactElement } from 'react'
import { Divider } from '@mui/material'
import LocationFilter from '@domains/location/LocationsFilter'
import { StatusFilter } from '@features/lists/components/Filters/StatusFilter'
import { CommunityLabelsFilter } from '@features/lists/components/Filters/CommunityLabelsFilter'
import RiskFilter from '@features/lists/components/Filters/RiskFilter'
import { useSelector } from 'react-redux'
import { InterviewTypesToCreate } from '@domains/integrations/InterviewTypeStateFilter'

interface Props {
	refetch: () => Promise<ApolloQueryResult<unknown>>
	mode?: Mode
}

export const EmployeesFilters = ({ refetch, mode = Mode.List }: Props): ReactElement | null => {
	const isList = mode === Mode.List
	const isRestrictedAccess: boolean = useSelector(
		({ userB }: { userB: { isRestrictedAccess: boolean } }) => userB.isRestrictedAccess
	)
	const { t } = useCustomTranslation()
	return (
		<Filters refetch={refetch}>
			{isList ? (
				<>
					<FiltersContent>
						<Fullname />
						<TextFilter keyName="userInfo.externalId" label={t('lists:filter.externalId')} />
					</FiltersContent>
					<Divider />
				</>
			) : null}
			<FiltersContent>
				<TrackPositionFilter keyName="orgaPosition.id" keyLabel="track" hasNoneValue={isList} />
				<BusinessUnitFilter hasNoneValue={isList} />
				<StatusFilter hasNoneValue={isList} />
				{!isRestrictedAccess && <ManagersFilter hasNoneValue={isList} />}
				<LocationFilter
					label={t('lists:filter.site.label')}
					allValueLabel={t('lists:filter.site.allLabel')}
					hasNoneValue={isList}
				/>
				{isList ? (
					<>
						<CheckboxInput keyName="isArchived" translationKey="lists:filter.archived" />
						<CheckboxInput keyName="isActive" translationKey="lists:filter.isActive" />
						<CheckboxInput keyName="isOnboardingCompleted" translationKey="lists:filter.isOnboardingCompleted" />
						<InterviewTypesToCreate />
						<RiskFilter />
						<CommunityLabelsFilter />
					</>
				) : null}
			</FiltersContent>
		</Filters>
	)
}

import { type ReactElement } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Stack } from '@mui/system'
import { Card, CardContent, Container, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { OrganizationInvitation } from './organization/OrganizationInvitation'
import { OrganizationUserInvitation } from './OrganizationUserInvitation'
import { OrganizationAdminInvitation } from './OrganizationAdminInvitation'
import { FormProvider, useForm } from 'react-hook-form'
import { useCreateOrganizationInvitationMutation } from '@gqlTypes'
import Center from '@ui/layout/Center'
import { emailRegex } from '@infrastructure/emailRegex'
import { OrganizationCreationPath } from './OrganizationCreation.router'
import { type OrganizationCreationForm } from './organizationCreationForm'

export const OrganizationCreation = (): ReactElement => {
	const { organizationType } = useParams<{ organizationType: string }>()
	const { t } = useCustomTranslation()
	const history = useHistory()
	const methods = useForm<OrganizationCreationForm>()
	const [createOrganizationInvitationMutation, { loading: isSaving }] = useCreateOrganizationInvitationMutation()

	const createOrganizationInvitation = async (data: OrganizationCreationForm): Promise<void> => {
		const organization = {
			name: data.organizationName,
			website: data.organizationWebsite,
			type: organizationType,
			logo: data.logo ?? '',
			VATNumber: data.organizationVAT,
			about: data.organizationAbout
		}
		const admin = {
			firstname: data.firstname,
			lastname: data.lastname,
			email: data.email,
			phone: data.phone,
			language: data.language
		}
		const emails = data.usersEmails.match(emailRegex) ?? []
		const variables = { createOrganizationInvitationInput: { organization, admin, emails } }
		await createOrganizationInvitationMutation({ variables })
		history.push(OrganizationCreationPath.sent)
	}
	return (
		<Container sx={{ mt: 10 }}>
			{/* eslint-disable-next-line */}
      <form onSubmit={methods.handleSubmit(createOrganizationInvitation)}>
				<Stack spacing={2} mt={2} sx={{ position: 'relative' }}>
					<img
						style={{ zIndex: 3, marginBottom: -35, alignSelf: 'center' }}
						width={35}
						height={35}
						src="/assets/img/icon-emage-me.png"
					/>
					<Card elevation={3} sx={{ borderRadius: '25px' }}>
						<CardContent sx={{ mt: 2 }}>
							<FormProvider {...methods}>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={4}>
										<OrganizationInvitation organizationType={organizationType} />
									</Grid>
									<Grid item xs={12} sm={4}>
										<OrganizationAdminInvitation />
									</Grid>
									<Grid item xs={12} sm={4}>
										<OrganizationUserInvitation />
									</Grid>
								</Grid>
								<Center>
									<LoadingButton type="submit" color="secondary" variant="contained" loading={isSaving}>
										{t('auth:createAccount')}
									</LoadingButton>
								</Center>
							</FormProvider>
						</CardContent>
					</Card>
				</Stack>
			</form>
		</Container>
	)
}

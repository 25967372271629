import { useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter, { type AutoCompleteFilterProps } from './AutoCompleteFilter'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type ReactElement } from 'react'

interface Props extends AutoCompleteFilterProps {}

export const StatusFilter = ({ ...props }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()

	const { data, loading: isLoading } = useMeQuery()
	if (isLoading || data?.me == null) return null
	const status = data.me.organization.offerStatusObject.map((status) => ({ value: status.id, label: status.label }))

	return (
		<InputFilter keyName="status" operation={filterOperation.IN_ARRAY}>
			<AutoCompleteFilter {...props} options={status} label={t('lists:filter.status')} multiple limitTags={5} />
		</InputFilter>
	)
}

import { type ReactElement } from 'react'
import { type UserPositionListNodeFragment } from '@gqlTypes'
import { IconButton, Checkbox, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import CommentModal from '@features/lists/components/modals/CommentModal/CommentModal'
import { usePositionUpdate } from '@domains/students/list/usePositionUpdate'
import { RiskModal } from '@domains/risk/RiskModal'

interface Props {
	position: UserPositionListNodeFragment
	isSelected?: boolean
	selectRow?: () => void
	isHovered: boolean
	dialogComponent: ReactElement
	setIsOpen: (isOpen: boolean) => void
	riskTitle: string
	isEditable: boolean
}

export const PositionCardActions = ({
	position,
	isSelected = false,
	selectRow,
	isHovered,
	dialogComponent,
	setIsOpen,
	riskTitle,
	isEditable
}: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { update } = usePositionUpdate(position)

	const isSelectable = selectRow != null && isSelected != null
	const iconColor = isHovered ? 'grays.gray2' : 'transparent'

	if (!isSelectable) return null
	return (
		<>
			{isEditable ? (
				<>
					<Tooltip title={t('students:actions.edit.tooltip')}>
						<IconButton
							onClick={() => {
								setIsOpen(true)
							}}
						>
							<EditIcon sx={{ color: iconColor }} />
						</IconButton>
					</Tooltip>
					{dialogComponent}
				</>
			) : null}

			<Tooltip title={t('students:actions.select.tooltip')}>
				<Checkbox
					sx={{ color: iconColor }}
					checked={isSelected}
					onClick={() => {
						selectRow()
					}}
				/>
			</Tooltip>
			<CommentModal
				id={position.id}
				defaultValue={position.comment ?? ''}
				onSelect={(comment) => {
					update({ comment })
				}}
				isHovered={isHovered}
			/>
			<RiskModal position={position} isHovered={isHovered} title={riskTitle} />
		</>
	)
}

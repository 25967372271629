import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useGetSupportedLanguagesQuery } from '@gqlTypes'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'

interface Props {
	name?: string
	label?: string
}

export const SupportedLanguageField = ({ name, label }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const { data } = useGetSupportedLanguagesQuery()
	const items = (data?.getSupportedLanguages ?? []).map((item) => ({ value: item.id, label: item.label }))
	const fieldName = name ?? 'language'

	return (
		<AutocompleteSelect
			label={label ?? t('myAccount:generalInformations.fields.language.title')}
			name={fieldName}
			items={items}
			rules={{ required: t('form:errors.required') }}
			disableClearable
		/>
	)
}

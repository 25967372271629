import { type ReactElement, useState } from 'react'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type GetInterviewTemplateListQuery, useGetInterviewTemplateListQuery } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import { Container, Typography } from '@mui/material'
import { CardList } from '@features/lists/components/CardList'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { InterviewTemplateCard } from './InterviewTemplateCard'

interface Props {
	customFilterName?: string
}

export const InterviewTemplateList = ({ customFilterName }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	useDocumentTitle(t('interviewTemplate:list.title'))

	const { filters } = useFilters(customFilterName)
	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)

	let cursor = ''
	let hasNextPage = false
	let count = 0
	const variables = { limit: 10, filters, cursor }
	const { loading, data, refetch, fetchMore } = useGetInterviewTemplateListQuery({
		variables,
		fetchPolicy: 'cache-and-network'
	})
	useDefaultFilter({ refetch })

	let nodes: GetInterviewTemplateListQuery['getInterviewTemplateList']['nodes'][0][] = []
	if (data?.getInterviewTemplateList != null) {
		;({ nodes, count } = data.getInterviewTemplateList)
		cursor = data?.getInterviewTemplateList.cursor ?? ''
		hasNextPage = data?.getInterviewTemplateList.hasNextPage ?? false
	}

	return (
		<Container maxWidth="xl">
			<CardList
				renderItem={(item) => <InterviewTemplateCard key={item.id} interviewTemplate={item} />}
				isAllRowsSelected={isAllRowsSelected}
				setIsAllRowsSelected={setIsAllRowsSelected}
				dataList={nodes}
				isLoading={loading}
				fetchMore={fetchMore}
				cursor={cursor}
				hasNextPage={hasNextPage}
				emptyComponent={<Typography sx={{ fontSize: 24 }}>{t('interviewTemplate:dataGrid.empty')}</Typography>}
				title={t('interviewTemplate:list.title', { count })}
				toolbar={<></>}
			/>
		</Container>
	)
}

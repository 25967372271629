import { type ReactElement } from 'react'
import { Alert, AlertTitle } from '@mui/material'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	columnRequired: string[]
}

export const ColumnRequiredAlert = ({ columnRequired }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const count = columnRequired.length

	return (
		<Alert severity="error">
			<AlertTitle>
				<b>{t('modal:userImport.columnRequired.title', { count })}</b> {columnRequired.join(', ')}
			</AlertTitle>
			{t('modal:userImport.columnRequired.message', { count })}
		</Alert>
	)
}

import { Box, Stack } from '@mui/material'
import CardLayout from '@ui/core/CardLayout'
import { type ReactElement, useState } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { ImportFileButton } from '@ui/fileUpload/ImportFileButton'
import { useUploadInterviewAttachment } from './hooks/useUploadInterviewAttachment'
import { useDeleteInterviewAttachment } from './hooks/useDeleteInterviewAttachment'
import { type GetInterviewDetailsQuery } from '@gqlTypes'
import { InterviewAttachmentDeleteDialog } from './InterviewAttachmentDeleteDialog'
import { InterviewAttachments } from './InterviewAttachments'
import { IntegrationAttachments } from './IntegrationAttachments'

interface Props {
	connectedUserId: string
	interview: GetInterviewDetailsQuery['getInterview']
}

export const InterviewAttachmentsCard = ({ interview, connectedUserId }: Props): ReactElement => {
	const { organizationId, isArchived, isSignedByAssessed, isSignedByAssessor } = interview

	const interviewId = interview.id ?? ''
	const interviewAttachments = interview.attachmentsObject ?? []
	const { t } = useCustomTranslation('translation:attachment')
	const [fileNameChosenToBeDeleted, setFileNameChosenToBeDeleted] = useState<string | null>(null)
	const { upload } = useUploadInterviewAttachment({
		connectedUserId,
		interviewId,
		organizationId,
		attachments: interviewAttachments
	})
	const { remove: onDelete } = useDeleteInterviewAttachment({ interviewId, attachments: interviewAttachments })

	return (
		<Box>
			<CardLayout title={t('title')}>
				<Box sx={{ mx: 4, my: 3 }}>
					{t('explanation')}
					<Stack sx={{ mt: 2 }} spacing={2}>
						<IntegrationAttachments interview={interview} />

						<InterviewAttachments
							connectedUserId={connectedUserId}
							interview={interview}
							setFileNameChosenToBeDeleted={setFileNameChosenToBeDeleted}
						/>
					</Stack>
					<InterviewAttachmentDeleteDialog
						{...{
							onDelete,
							fileName: fileNameChosenToBeDeleted,
							setFileName: setFileNameChosenToBeDeleted
						}}
					/>
				</Box>
				{!isArchived && !isSignedByAssessor && !isSignedByAssessed ? (
					<Stack className="no-print" direction="row" sx={{ ml: 3, mb: 3 }}>
						<ImportFileButton
							onSelectFile={upload}
							label={t('button')}
							accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						/>
					</Stack>
				) : null}
			</CardLayout>
		</Box>
	)
}

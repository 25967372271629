import { MassMessages } from '../../../features/modals/pages/MassMessages'
import { MassReminders } from '../../../features/modals/pages/MassReminders'
import { MassFeedback } from '@domains/brandFeedbacks/massFeedback/MassFeedback'
import {
	CommunityMemberExportDocument,
	type GetCommunityMembersListQuery,
	type GetCommunityMembersListQueryVariables,
	IntegrationCategory,
	TrackType,
	useMeQuery,
	useUpdateManyUserIntegrationsMutation
} from '@gqlTypes'
import { MassUpdatesGql } from '@features/modals/pages/MassUpdatesGql'
import { type ApolloQueryResult } from '@apollo/client'
import { ExportGQL } from '@features/modals/components/actions/ExportGQL'
import { LeftBar } from '@features/lists/components/LeftBar'
import { type ReactElement } from 'react'
import { useRole } from '@shared/hooks/useRole'
import { CommunityMembersFilters } from '../CommunityMembersFilter'

interface Props {
	isAllRowsSelected: boolean
	refetch: (
		variables?: Partial<GetCommunityMembersListQueryVariables>
	) => Promise<ApolloQueryResult<GetCommunityMembersListQuery>>
	dataList: NonNullable<GetCommunityMembersListQuery['getCommunityMembersList']>['nodes']
	count: number
}

export const CommunityMembersFilterBar = ({ isAllRowsSelected, refetch, dataList, count }: Props): ReactElement => {
	const [updateManyUserIntegrations, { loading: mutationLoading }] = useUpdateManyUserIntegrationsMutation()
	const { data: me, loading: meLoading } = useMeQuery()
	const { screens } = useRole()
	const isLoading = mutationLoading || meLoading
	const hasOrgaSkills = (me?.me?.organization.orgaSkills?.length ?? 0) > 0
	const massAction = {
		trackType: TrackType.Position,
		integrationCategory: IntegrationCategory.Community,
		isAllRowsSelected,
		dataList
	}

	return (
		<LeftBar>
			{screens.communityMembers.hasAdminAccess ? (
				<MassUpdatesGql
					{...massAction}
					query={updateManyUserIntegrations}
					refetch={refetch}
					isLoading={isLoading}
					count={count}
				/>
			) : null}
			<MassMessages {...massAction} count={count} />
			<MassReminders {...massAction} />
			{hasOrgaSkills ? <MassFeedback {...massAction} count={count} /> : null}
			<ExportGQL isAllRowsSelected={isAllRowsSelected} dataList={dataList} QUERY={CommunityMemberExportDocument} />
			<CommunityMembersFilters refetch={refetch} />
		</LeftBar>
	)
}

import { useMeQuery } from '@gqlTypes'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'

export const UserFunctionFilter = (): ReactElement | null => {
	const { t } = useCustomTranslation()

	const { data, loading: isLoading } = useMeQuery()

	const userFunctions = (data?.me?.organization?.matchingRestrictions?.userFunctions ?? []).map((userFunction) => ({
		value: userFunction?.id ?? '',
		label: userFunction?.name ?? ''
	}))

	if (userFunctions.length <= 1 || isLoading || data?.me == null) return null

	return (
		<InputFilter keyName="offerFunction" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter
				options={userFunctions}
				label={t('lists:filter.offerFunction.label')}
				hasNoneValue={false}
				allValueLabel={t('lists:filter.offerFunction.allLabel')}
			/>
		</InputFilter>
	)
}

import { useGetGeoLocationsQuery } from '@gqlTypes'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'

export const OfferLocationFilter = (): ReactElement | null => {
	const { t } = useCustomTranslation()

	const { data, loading: isLoading } = useGetGeoLocationsQuery()

	if (isLoading || data?.getGeoLocations == null) return null
	const locations = data.getGeoLocations.map((location) => ({
		value: location.id,
		label: location.label
	}))

	return (
		<InputFilter keyName="location.id" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter
				options={locations}
				label={t('lists:filter.location.label')}
				hasNoneValue={false}
				allValueLabel={t('lists:filter.location.allLabel')}
			/>
		</InputFilter>
	)
}

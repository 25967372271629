import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Typography, type TypographyProps } from '@mui/material'

interface Props extends TypographyProps {
	translationKey: string
	count: number
}

export const UserImportResultCount = ({ translationKey, count, ...props }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	return <Typography {...props}>{t(`modal:userImport.${translationKey}`, { count })}</Typography>
}

import { OrganizationCreation } from './OrganizationCreation.page'
import { OrganizationCreationSent } from './OrganizationCreationSent.page'
import { OrganizationInternshipCreation } from './OrganizationInternshipCreation.page'

export enum OrganizationCreationPath {
	create = '/organization-creation/:organizationType',
	createInternship = '/organization-creation/internship/:authorizedOrganizationId?',
	sent = '/organization-creation/sent'
}
export const organizationCreationRoutes = [
	{
		path: OrganizationCreationPath.sent,
		public: true,
		children: <OrganizationCreationSent />
	},
	{
		path: OrganizationCreationPath.createInternship,
		public: true,
		children: <OrganizationInternshipCreation />
	},
	{
		path: OrganizationCreationPath.create,
		public: true,
		children: <OrganizationCreation />
	}
]

import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	organizationType: string
}

export const OrganizationVAT = ({ organizationType }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const message = t('organizationCreation:requiredField')

	return (
		<InputTextFieldForm
			name="organizationVAT"
			label={t(`organizationCreation:organization.${organizationType}.field.VAT`)}
			rules={{
				required: { value: true, message },
				pattern: {
					value: /^(CHE-\d{3}\.\d{3}\.\d{3}\sTVA|[A-Z]{2}\d{8,12})$/,
					message: t('organizationCreation:error.VAT')
				}
			}}
		/>
	)
}

import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Grid, Stack } from '@mui/material'
import CardLayout from '@ui/core/CardLayout'
import { ExitFormWarning } from '@ui/form/ExitFormWarning'
import { type ReactElement } from 'react'
import { FormProvider } from 'react-hook-form'
import { CancelFormButton } from '@ui/form/buttons/CancelFormButton'
import { UpdateFormButton } from '@ui/form/buttons/UpdateFormButton'
import { useUpdateMyOrganizationForm } from './useUpdateMyOrganizationForm'
import { NameInput } from '../fields/NameInput'
import { WebsiteInput } from '../fields/WebsiteInput'
import { AboutInput } from '../fields/AboutInput'
import { InterviewTypesInput } from '../fields/InterviewTypesInput'
import { SupportedLanguageField } from '@domains/language/SupportedLanguageField'
import { MyOrganizationLayout, TabId } from './MyOrganizationLayout'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'

export const OrganizationConfiguration = (): ReactElement => {
	const { t } = useCustomTranslation('organization:myOrganization')
	useDocumentTitle(t('menu.settings'))

	const { save, isSaving, onError, formMethods } = useUpdateMyOrganizationForm()
	const {
		handleSubmit,
		reset,
		formState: { isDirty }
	} = formMethods

	const isActionsDisabled = !isDirty

	return (
		<MyOrganizationLayout tab={TabId.Settings}>
			<FormProvider {...formMethods}>
				<ExitFormWarning isModified={isDirty} />
				<CardLayout
					footer={
						<Stack direction="row" spacing={3}>
							<CancelFormButton
								disabled={isActionsDisabled}
								onClick={() => {
									reset()
								}}
							/>
							<UpdateFormButton disabled={isActionsDisabled} loading={isSaving} onClick={handleSubmit(save, onError)} />
						</Stack>
					}
				>
					<Grid container spacing={2} p={2}>
						<Grid item md={6}>
							<Stack spacing={2}>
								<NameInput />
								<WebsiteInput />
								<InterviewTypesInput />
								<SupportedLanguageField name="defaultLanguage" label={t('generalSettings.defaultLanguage.label')} />
							</Stack>
						</Grid>
						<Grid item md={6}>
							<AboutInput />
						</Grid>
					</Grid>
				</CardLayout>
			</FormProvider>
		</MyOrganizationLayout>
	)
}

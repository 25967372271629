import { type ReactElement } from 'react'
import { Alert, AlertTitle } from '@mui/material'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	columnFound: string[]
	columnNotFound: string[]
	configuredColumn: string[]
}

export const ColumnFoundAlert = ({ columnFound, columnNotFound, configuredColumn }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()

	return (
		<>
			<Alert severity="success">
				<AlertTitle>
					<b>{t('modal:userImport.columnFound.title', { count: columnFound.length })}</b> {columnFound.join(', ')}
				</AlertTitle>
			</Alert>
			{columnNotFound.length > 0 ? (
				<Alert severity="warning">
					<AlertTitle>
						<b>{t('modal:userImport.columnNotFound.title', { count: columnNotFound.length })}</b>{' '}
						{columnNotFound.join(', ')}
					</AlertTitle>
					{t('modal:userImport.columnNotFound.message')} {configuredColumn.join(', ')}
				</Alert>
			) : null}
		</>
	)
}

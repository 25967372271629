import { type ApolloError } from '@apollo/client'
import { useHistory, useParams } from 'react-router-dom'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type Role } from './role'
import { useSnackbar } from 'notistack'
import { Severities } from '@application/snackbar/Severities'
import {
	type CreateUserMutation,
	GetOrganizationUserListDocument,
	type GetUserQuery,
	type UpdateUserMutation,
	type UserInput,
	useCreateUserMutation,
	useGetUserQuery,
	useUpdateUserMutation
} from '@gqlTypes'
import { OrganizationUsersRouter } from './OrganizationUsers.router'
import { generateRoute } from '../../../router/routes'

interface Hook {
	roles: Role[]
	defaultValues: GetUserQuery['getUser']
	availableOffers: { value: string; label: string }[]
	availablePositions: { value: string; label: string; category: string }[]
	isLoading: boolean
	isSaving: boolean
	saveUser: (userInput: UserInput) => Promise<void>
	managers: GetUserQuery['getManagers']
}

export default function useUserDetails(): Hook {
	const history = useHistory()
	const { userId } = useParams<{ userId: string }>()
	const isCreateForm = userId === 'new'
	const { t } = useCustomTranslation()
	useDocumentTitle(t('organizationUsers:list.document.title'))
	const variables = { userId }
	const { data, loading: isLoading } = useGetUserQuery({ variables })
	const [update, { loading: isUpdateLoading }] = useUpdateUserMutation()
	const [create, { loading: isCreateLoading }] = useCreateUserMutation()
	const saveUserMutation = isCreateForm ? create : update

	const { enqueueSnackbar } = useSnackbar()
	const refetchQueries = [{ query: GetOrganizationUserListDocument }]
	const onCompleted = (data: UpdateUserMutation | CreateUserMutation | null | undefined): void => {
		const successMessage = t(`organizationUsers:list.success.${isCreateForm ? 'create' : 'update'}`)
		const variant = Severities.success
		enqueueSnackbar(successMessage, { variant })

		history.push(
			isCreateForm
				? generateRoute(OrganizationUsersRouter.paths.positions, {
						userId: data?.createUser?.id ?? '',
						isSkippable: 'true'
					})
				: OrganizationUsersRouter.paths.list
		)
	}
	const onError = (error: ApolloError): void => {
		enqueueSnackbar(error.message, { variant: Severities.error })
	}
	const saveUser = async (userInput: UserInput): Promise<void> => {
		if (userInput.offersAccessIds != null) {
			userInput.offersAccessIds = userInput.offersAccessIds.map(
				(offer: { label: string; value: string }) => offer.value
			)
		}
		if (userInput.positionsAccessIds != null) {
			userInput.positionsAccessIds = userInput.positionsAccessIds.map(
				(position: { label: string; value: string }) => position.value
			)
		}
		const variables = isCreateForm ? { userInput } : { userId, userInput }
		await saveUserMutation({ variables, refetchQueries, onCompleted, onError })
	}

	return {
		isLoading,
		isSaving: isCreateLoading || isUpdateLoading,
		saveUser,
		defaultValues: data?.getUser,
		availableOffers: data?.getTrackOfferList?.nodes ?? [],
		availablePositions: data?.getTrackPositionList?.nodes ?? [],
		roles: data?.getRoles ?? [],
		managers: data?.getManagers ?? []
	}
}

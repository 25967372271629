import { ScreenId } from '@gqlTypes'
import { EmployeesList } from './list/EmployeesList'
import { EmployeesDetails } from './details/EmployeesDetails'
import { EmployeesDashboard } from './dashboard/EmployeesDashboard'

enum Path {
	list = '/employees',
	details = '/employees/:userPositionId',
	dashboard = '/employees/dashboard'
}

const routes = [
	{
		path: Path.list,
		children: <EmployeesList />
	},
	{
		path: Path.dashboard,
		children: <EmployeesDashboard />
	},
	{
		path: Path.details,
		children: <EmployeesDetails />,
		notExact: true
	}
]

export const EmployeesRouter = {
	roleId: ScreenId.Employees,
	paths: Path,
	routes
}

import { Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import MDIIcon from '@ui/icon/MDIIcon'

interface Props {
	currentCompany: string
	publicTitle: string
}

export const PublicTitle = ({ currentCompany, publicTitle }: Props): ReactElement | null => {
	const label = [currentCompany, publicTitle].filter((str) => str !== '').join(' • ')
	if (label === '') return null

	return (
		<Stack direction="row" gap={1} alignItems="center">
			<MDIIcon sx={{ color: 'grays.gray3' }} name="town-hall" size={18} />
			<Typography sx={{ fontSize: '14px', color: 'grays.gray3' }} textOverflow="ellipsis" noWrap>
				{label}
			</Typography>
		</Stack>
	)
}

import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Tabs, Tab } from '@mui/material'
import { DefaultLayout } from '@ui/layout/DefaultLayout'
import { type ReactNode, type ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { organizationRouter } from '../organization.router'
import { useRole } from '@shared/hooks/useRole'
import { ROUTE_HOME } from '../../../router/routes'
import { useMeQuery } from '@gqlTypes'

export enum TabId {
	Settings = 'settings',
	TrackOffers = 'track-offers',
	TrackPositions = 'track-positions',
	InterviewTemplates = 'interview-template'
}

interface Props {
	children: ReactNode
	tab: TabId
}

export const MyOrganizationLayout = ({ children, tab }: Props): ReactElement => {
	const { t } = useCustomTranslation('organization:myOrganization')
	const history = useHistory()
	const { screens } = useRole()
	const { data } = useMeQuery()

	if (!screens.myOrganization.hasWriteAccess) history.replace(ROUTE_HOME)
	const hasTrackPositionScreen = screens.tracksPosition.hasWriteAccess
	const hasTrackOfferScreen = screens.tracksOffer.hasWriteAccess

	return (
		<DefaultLayout
			containerStyle={{ display: 'block' }}
			title={t('title', { organizationName: data?.me?.organization?.name ?? '' })}
			right={
				<Tabs
					value={tab}
					onChange={(_, value) => {
						history.push(organizationRouter.path.default + value)
					}}
				>
					<Tab wrapped value={TabId.Settings} label={t('menu.settings')} />
					{hasTrackPositionScreen ? <Tab wrapped value={TabId.TrackOffers} label={t('menu.trackOffers')} /> : null}
					{hasTrackOfferScreen ? <Tab wrapped value={TabId.TrackPositions} label={t('menu.trackPositions')} /> : null}
					<Tab wrapped value={TabId.InterviewTemplates} label={t('menu.interviewTemplates')} />
				</Tabs>
			}
		>
			{children}
		</DefaultLayout>
	)
}

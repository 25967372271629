import { useGetUserFunctionLazyQuery, useMeQuery } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { useEffect, useState, type ReactElement } from 'react'
import { TopHardskillFilters } from './TopHardskillFilters'

export const HardSkillFilter = (): ReactElement | null => {
	const { filters, setFilters } = useFilters()
	const { data: dataMe, loading: isMeLoading } = useMeQuery()
	const restrictedFunctionIds = (dataMe?.me?.organization?.matchingRestrictions?.userFunctions ?? []).map(
		({ id }) => id
	)
	const userFunctionId: string =
		restrictedFunctionIds.length === 1
			? restrictedFunctionIds[0]
			: (filters.find((filter) => filter.key === 'userFunctionId')?.value ?? '')

	const [macroHardSkillItems, setMacroHardSkillItems] = useState<{ value: string; label: string }[]>([])
	const [microHardSkillItems, setMicroHardSkillItems] = useState<{ value: string; label: string }[]>([])
	const [currentFunctionId, setCurrentFunctionId] = useState<string>(userFunctionId)

	const [getFunctionData, { loading }] = useGetUserFunctionLazyQuery({
		onCompleted: (res) => {
			const microSkills = (res?.getUserFunction?.microHardSkills ?? []).map((item) => ({
				value: item.id ?? '',
				label: item.label ?? ''
			}))

			const macroSkills = (res?.getUserFunction?.macroHardSkills ?? []).map((item) => ({
				value: item.id ?? '',
				label: item.label ?? ''
			}))

			setMacroHardSkillItems(macroSkills)
			setMicroHardSkillItems(microSkills)
			setCurrentFunctionId(userFunctionId)
		}
	})

	useEffect(() => {
		if (userFunctionId !== '' && userFunctionId !== 'empty') void getFunctionData({ variables: { userFunctionId } })
		if (currentFunctionId !== userFunctionId) setFilters(filters.filter((filter) => filter.key !== 'topHardSkillIds'))
	}, [userFunctionId, getFunctionData])

	if (userFunctionId === '' || userFunctionId === 'empty') return null
	const isLoading = loading || isMeLoading

	return (
		<InputFilter keyName={'topHardSkillIds'} operation={filterOperation.CONTAIN_ANY_ALL}>
			<TopHardskillFilters {...{ macroHardSkillItems, microHardSkillItems, isLoading }} />
		</InputFilter>
	)
}

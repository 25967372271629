import { AutocompleteSelect, type AutocompleteSelectProps } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'
import { useOrganization } from '@domains/organization/useOrganization'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props extends Partial<AutocompleteSelectProps> {}

export const LocationSelect = (props: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const { organization } = useOrganization()
	const availableLocations = organization?.locations.map((bu: string) => ({ value: bu, label: bu })) ?? []
	return (
		<AutocompleteSelect
			name="location"
			noneValueLabel={t('organizationUsers:details.cards.tracks.form.employee.location.none')}
			items={availableLocations}
			label={t('organizationUsers:details.cards.tracks.form.employee.location.label')}
			{...props}
		/>
	)
}

import AutoCompleteFilter, { type AutoCompleteFilterProps } from '@features/lists/components/Filters/AutoCompleteFilter'
import { type UserName } from '@gqlTypes'
import { type ReactElement } from 'react'

interface Props extends Partial<AutoCompleteFilterProps> {
	users: UserName[]
}

export const UsersAutocompleteFilter = ({ users, ...props }: Props): ReactElement | null => {
	const availableUsers = users.map(({ id, reverseFullName, email }) => ({
		value: id,
		label: `${reverseFullName}\n <${email}>`
	}))
	if (users.length === 0) return null
	return <AutoCompleteFilter {...props} options={availableUsers} />
}

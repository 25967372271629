import { AutocompleteSelect, type AutocompleteSelectProps } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'
import { useOrganization } from '@domains/organization/useOrganization'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props extends Partial<AutocompleteSelectProps> {}

export const PromotionSelect = (props: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const { organization } = useOrganization()
	const availablePromotions = organization?.promotions.map((bu: string) => ({ value: bu, label: bu })) ?? []
	return (
		<AutocompleteSelect
			name="promotion"
			noneValueLabel={t('organizationUsers:details.cards.tracks.form.promotion.none')}
			items={availablePromotions}
			label={t('organizationUsers:details.cards.tracks.form.promotion.label')}
			{...props}
		/>
	)
}

import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Stack } from '@mui/material'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import CardLayout from '@ui/core/CardLayout'
import { ExitFormWarning } from '@ui/form/ExitFormWarning'
import { DefaultLayout } from '@ui/layout/DefaultLayout'
import { type ReactElement } from 'react'
import { FormProvider } from 'react-hook-form'
import { useUpdateMyAccountForm } from './useUpdateMyAccountForm'
import { AvatarField } from '@domains/myProfile/account/AvatarField'
import { FirstNameInput } from '@domains/user/components/FirstNameInput'
import { LastNameInput } from '@domains/user/components/LastNameInput'
import { PublicTitleInput } from '@domains/user/components/PublicTitleInput'
import { CancelFormButton } from '@ui/form/buttons/CancelFormButton'
import { UpdateFormButton } from '@ui/form/buttons/UpdateFormButton'
import { SupportedLanguageField } from '@domains/language/SupportedLanguageField'

export const MyAccount = (): ReactElement => {
	const { t } = useCustomTranslation()
	useDocumentTitle(t('myAccount:title'))

	const { save, isSaving, onError, formMethods, setAvatarLocalFile, avatarLocalFile } = useUpdateMyAccountForm()
	const {
		handleSubmit,
		reset,
		formState: { isDirty }
	} = formMethods

	const isActionsDisabled = !isDirty && avatarLocalFile == null

	return (
		<DefaultLayout title={t('myAccount:title')}>
			<FormProvider {...formMethods}>
				<ExitFormWarning isModified={isDirty} />
				<CardLayout
					title={t('myAccount:generalInformations.title')}
					footer={
						<Stack direction="row" spacing={3}>
							<CancelFormButton
								disabled={isActionsDisabled}
								onClick={() => {
									reset()
									setAvatarLocalFile(null)
								}}
							/>
							<UpdateFormButton disabled={isActionsDisabled} loading={isSaving} onClick={handleSubmit(save, onError)} />
						</Stack>
					}
				>
					<Stack spacing={4} p={2}>
						<Stack direction="row" spacing={4} sx={{ justifyContent: 'space-around' }}>
							<AvatarField onSelectFile={setAvatarLocalFile} avatarLocalFile={avatarLocalFile} />
							<Stack spacing={2}>
								<FirstNameInput />
								<LastNameInput />
							</Stack>
						</Stack>
						<SupportedLanguageField />
						<PublicTitleInput />
					</Stack>
				</CardLayout>
			</FormProvider>
		</DefaultLayout>
	)
}

import { useCustomTranslation } from '#translations/useCustomTranslation'
import { LoadingButton, type LoadingButtonProps } from '@mui/lab'
import { type ReactElement } from 'react'

export const UpdateFormButton = (buttonProps: LoadingButtonProps): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<LoadingButton variant="contained" color="secondary" {...buttonProps}>
			{t('form:button.update.label')}
		</LoadingButton>
	)
}

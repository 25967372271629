import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter, {
	type AutoCompleteFilterProps
} from '../../features/lists/components/Filters/AutoCompleteFilter'
import { useOrganization } from '@domains/organization/useOrganization'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Status {
	id: string
	label: string
}

export default function PositionStatusFilter(props: AutoCompleteFilterProps): JSX.Element {
	const { t } = useCustomTranslation()
	const { organization } = useOrganization()
	const positionStatus = organization?.positionStatus ?? []
	const status = positionStatus.map((status: Status) => ({ value: status.id, label: status.label }))

	return (
		<InputFilter keyName="status" operation={filterOperation.IN_ARRAY}>
			<AutoCompleteFilter
				{...props}
				options={status}
				label={t('lists:filter.status')}
				multiple
				limitTags={5}
				width="100%"
			/>
		</InputFilter>
	)
}

import { type ReactElement } from 'react'
import { MyOrganizationLayout, TabId } from './MyOrganizationLayout'
import { TrackList } from '@domains/tracks/list/TracksList'
import { useHistory } from 'react-router-dom'
import { useRole } from '@shared/hooks/useRole'
import { ROUTE_HOME } from '../../../router/routes'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'

export const TrackPositionsConfiguration = (): ReactElement => {
	const history = useHistory()
	const { screens } = useRole()
	const { t } = useCustomTranslation()
	useDocumentTitle(t('organization:myOrganization.menu.trackPositions'))

	if (!screens.tracksPosition.hasWriteAccess) history.replace(ROUTE_HOME)
	return (
		<MyOrganizationLayout tab={TabId.TrackPositions}>
			<TrackList />
		</MyOrganizationLayout>
	)
}

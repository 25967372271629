import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { useGetGeoLocationDepartmentsQuery } from '@gqlTypes'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const GeoLocationFilter = (): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { data, loading } = useGetGeoLocationDepartmentsQuery()
	const items = data?.getGeoLocationDepartments ?? []
	if (items.length <= 1) return null
	return (
		<InputFilter keyName={'locationIds'} operation={filterOperation.CONTAIN}>
			<AutoCompleteFilter
				options={items}
				isLoading={loading}
				width="100%"
				label={t('offers:resumeLibrary.filters.location.label')}
				allValueLabel={t('offers:resumeLibrary.filters.location.allLabel')}
				hasNoneValue={false}
			/>
		</InputFilter>
	)
}

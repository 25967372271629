import { type ReactElement } from 'react'
import { Alert, AlertTitle, Typography } from '@mui/material'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	fieldErrorCounts: { field: string; count: number }[]
}

export const FieldErrorAlert = ({ fieldErrorCounts }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()

	if (fieldErrorCounts.length === 0) return null

	return (
		<Alert severity="warning">
			<AlertTitle>
				<b>{t('modal:userImport.fieldErrorCounts.title', { count: fieldErrorCounts.length })}</b>
			</AlertTitle>
			{fieldErrorCounts.map((fieldErrorCount) => (
				<Typography key={fieldErrorCount.field}>
					<b>{`${fieldErrorCount.field}: `}</b>
					{`${fieldErrorCount.count} ${t('modal:userImport.fieldErrorCounts.value', { count: fieldErrorCount.count })}`}
				</Typography>
			))}
		</Alert>
	)
}

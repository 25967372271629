import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'
import { LoadingButton } from '@mui/lab'
import { ConfirmationDialog } from '@ui/dialog/ConfirmationDialog'
import { useState, type ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useRevokeUserAccessMutation } from '@gqlTypes'
import { Typography } from '@mui/material'
import { OrganizationUsersRouter } from '@domains/organizationUsers/details/OrganizationUsers.router'

interface Props {
	userId: string
}

export const RemoveUserAccessButton = ({ userId }: Props): ReactElement => {
	const history = useHistory()
	const { t } = useCustomTranslation()
	const { onError, onCompleted } = useGqlSnackBar('organizationUsers:details.bar.kickUser.kick')
	const [isOpen, setIsOpen] = useState(false)
	const [kickUser, { loading: isDeleting }] = useRevokeUserAccessMutation({
		variables: { userId },
		onError,
		onCompleted
	})

	return (
		<>
			<LoadingButton
				color="error"
				variant="contained"
				onClick={() => {
					setIsOpen(true)
				}}
				disabled={isDeleting}
			>
				{t('organizationUsers:details.bar.kickUser.button')}
			</LoadingButton>
			<ConfirmationDialog
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				title={t('organizationUsers:details.bar.kickUser.title')}
				cancelLabel={t('organizationUsers:details.bar.kickUser.cancel')}
				confirmationLabel={t('organizationUsers:details.bar.kickUser.kick.label')}
				onConfirmation={async () => {
					await kickUser()
					history.push(OrganizationUsersRouter.paths.list)
				}}
			>
				<Typography>{t('organizationUsers:details.bar.kickUser.label')}</Typography>
			</ConfirmationDialog>
		</>
	)
}

import { type FilterInput } from '@gqlTypes'
import { createContext, type FormEvent, type ReactElement, type ReactNode, useState } from 'react'

export enum FilterOperation {
	EQUAL = '==',
	GREATER_THAN_EQUAL = '>=',
	GREATER_THAN = '>',
	LOWER_THAN = '<',
	LOWER_THAN_EQUAL = '<=',
	SEARCH_STRING = 'search',
	IN_ARRAY = 'in',
	NOT_IN_ARRAY = 'not in',
	DIFFERENT = '!=',
	CONTAIN = 'array-contains',
	CONTAIN_ANY = 'array-contains-any',
	CONTAIN_ANY_ALL = 'array-contains-all'
}

export const filterOperation = {
	EQUAL: FilterOperation.EQUAL,
	GREATER_THAN_EQUAL: FilterOperation.GREATER_THAN_EQUAL,
	GREATER_THAN: FilterOperation.GREATER_THAN,
	LOWER_THAN: FilterOperation.LOWER_THAN,
	LOWER_THAN_EQUAL: FilterOperation.LOWER_THAN_EQUAL,
	SEARCH_STRING: FilterOperation.SEARCH_STRING,
	IN_ARRAY: FilterOperation.IN_ARRAY,
	NOT_IN_ARRAY: FilterOperation.NOT_IN_ARRAY,
	DIFFERENT: FilterOperation.DIFFERENT,
	CONTAIN: FilterOperation.CONTAIN,
	CONTAIN_ANY: FilterOperation.CONTAIN_ANY,
	CONTAIN_ANY_ALL: FilterOperation.CONTAIN_ANY_ALL
}

export const FilterContext = createContext({
	setFilter: (filters: FilterInput[]) => {},
	getFilter: (): FilterInput[] => {
		return []
	},
	makeFilter: (key: string, operation: string, value: unknown, isExclusive: boolean): FilterInput => {
		return { key, operation, value, isExclusive }
	}
})

interface Props {
	value?: FilterInput[]
	onChange?: (filters: FilterInput[]) => void
	defaultValue?: FilterInput[]
	onSubmit?: (filters: FilterInput[]) => void
	children: ReactNode
}

export const FilterForm = ({ value, onChange, defaultValue, onSubmit, children }: Props): ReactElement => {
	let [filters, setFilters] = useState<FilterInput[]>(defaultValue ?? [])
	const isControlled = value !== undefined
	if (isControlled) filters = value

	const handleSubmit = (e: FormEvent): void => {
		e.preventDefault()
		if (onSubmit != null) onSubmit(filters)
	}

	const getFilter = (): FilterInput[] => {
		return filters
	}

	const setFilter = (filters: FilterInput[]): void => {
		if (onChange != null) onChange(filters)
		if (!isControlled) setFilters(filters)
	}

	const makeFilter = (key: string, operation: string, value: unknown, isExclusive: boolean): FilterInput => {
		return { key, operation, value, isExclusive }
	}

	return (
		<FilterContext.Provider value={{ setFilter, getFilter, makeFilter }}>
			<form
				onSubmit={(e) => {
					handleSubmit(e)
				}}
			>
				{children}
			</form>
		</FilterContext.Provider>
	)
}

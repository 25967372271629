import { type ReactElement, useState } from 'react'
import { MassMessagesModal } from '../components/actions/MassMessagesModal'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useSelector } from 'react-redux'
import { IconAction } from '@ui/icon/IconAction'
import { type IntegrationCategory, type TrackType } from '@gqlTypes'

interface Props {
	isAllRowsSelected: boolean
	dataList: { id: string }[]
	trackType: TrackType
	count: number
	integrationCategory: IntegrationCategory
}

export const MassMessages = ({
	count,
	trackType,
	dataList,
	isAllRowsSelected,
	integrationCategory
}: Props): ReactElement => {
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useCustomTranslation()
	const selectedRows = useSelector(({ selectedRows }: { selectedRows: number[] }) => selectedRows)
	const userIntegrationIds = selectedRows.map((index) => dataList[index]?.id)

	return (
		<>
			<IconAction
				tooltip={t('modal:massMessages.tooltip')}
				disabled={selectedRows.length < 1}
				onClick={() => {
					setIsOpen(true)
				}}
			>
				<SmsOutlinedIcon />
			</IconAction>
			<MassMessagesModal
				isOpen={isOpen}
				rowCount={count}
				setIsOpen={setIsOpen}
				userIntegrationsIds={isAllRowsSelected ? [] : userIntegrationIds}
				trackType={trackType}
				integrationCategory={integrationCategory}
			/>
		</>
	)
}

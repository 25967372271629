import { Button, Card, type CardProps, Grid, Stack, Typography } from '@mui/material'
import { type GetMenuTilesQuery } from '@gqlTypes'
import MDIIcon from '@ui/icon/MDIIcon'
import { Link } from 'react-router-dom'
import { type ReactElement } from 'react'
import { TileCompletionMode, TileIndicator } from '../../features/home/components/TileIndicator'

interface Props extends CardProps {
	tile: GetMenuTilesQuery['getMenuTiles'][0]
	indicatorCompletionMode?: TileCompletionMode
}

export const DashboardTile = ({
	tile: { label, icon, localizedUrl, color, indicators },
	sx,
	indicatorCompletionMode
}: Props): ReactElement => {
	const colorAlpha20 = `${color}33`

	const primaryIndicator = indicators[0] ?? {}
	const secondaryIndicators = indicators.slice(1) ?? []

	return (
		<Link to={localizedUrl}>
			<Button sx={{ padding: 0, margin: '12px', fontWeight: 400, borderRadius: '25px' }}>
				<Card
					sx={{
						boxShadow: 3,
						height: 160,
						borderRadius: '25px',
						width: 310,
						padding: '20px',
						'&:hover': { boxShadow: 10 },
						...sx
					}}
				>
					<Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
						<Stack
							sx={{
								width: 50,
								height: 50,
								backgroundColor: colorAlpha20,
								borderRadius: '20px',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<MDIIcon sx={{ color }} name={icon} size={30} />
						</Stack>
						<Typography sx={{ color: '#000', fontSize: 20, fontWeight: 'bold' }}>{label}</Typography>
					</Stack>
					<Grid container sx={{ mt: 1, textAlign: 'left' }}>
						<Grid item md={4}>
							<TileIndicator
								pathname={localizedUrl}
								indicator={primaryIndicator}
								key={label + primaryIndicator.label}
								completionMode={TileCompletionMode.None}
								isPrimary
							/>
						</Grid>
						<Grid item md={8}>
							<Stack direction="row" flexWrap={'wrap'}>
								<Grid container rowSpacing={2}>
									{secondaryIndicators.map((indicator) => (
										<TileIndicator
											pathname={localizedUrl}
											indicator={indicator}
											key={label + indicator.label}
											completionMode={indicatorCompletionMode}
										/>
									))}
								</Grid>
							</Stack>
						</Grid>
					</Grid>
				</Card>
			</Button>
		</Link>
	)
}

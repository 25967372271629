import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type ApolloQueryResult } from '@apollo/client'
import { Mode } from '@application/dashboard/ModeToggle'
import { Filters } from '@features/lists/components/Filters'
import BusinessUnitFilter from '@domains/businessUnit/BusinessUnitFilter'
import { CheckboxInput } from '@features/lists/components/Filters/CheckboxInput'
import { Fullname } from '@features/lists/components/Filters/Fullname'
import ManagersFilter from '@domains/manager/ManagersFilter'
import { TextFilter } from '@features/lists/components/Filters/TextFilter'
import { TrackPositionFilter } from '@features/lists/components/Filters/TrackPositionFilter'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import { type ReactElement } from 'react'
import { Divider } from '@mui/material'
import { useMeQuery } from '@gqlTypes'

interface Props {
	refetch: () => Promise<ApolloQueryResult<unknown>>
	mode?: Mode
}

export const CommunityMembersFilters = ({ refetch, mode = Mode.List }: Props): ReactElement | null => {
	const { data } = useMeQuery()
	const isList = mode === Mode.List
	const isRestrictedAccess = data?.me?.isRestrictedAccess ?? false
	const { t } = useCustomTranslation()
	return (
		<Filters refetch={refetch}>
			<>
				{isList ? (
					<>
						<FiltersContent>
							<Fullname />
							<TextFilter keyName="userInfo.externalId" label={t('lists:filter.externalId')} />
						</FiltersContent>
						<Divider />
					</>
				) : null}
				<FiltersContent>
					<TrackPositionFilter keyName="orgaPosition.id" keyLabel="track" hasNoneValue={isList} />
					<BusinessUnitFilter hasNoneValue={isList} />
					{!isRestrictedAccess && <ManagersFilter keyLabel="referents" hasNoneValue={isList} />}
					<CheckboxInput
						keyName="steps.brandFeedbackRequest"
						translationKey="lists:filter.steps.brandFeedbackRequest"
						defaultValue={undefined}
						isReversedValue={undefined}
					/>
					<CheckboxInput
						keyName="steps.brandFeedbackResponse"
						translationKey="lists:filter.steps.brandFeedbackResponse"
						defaultValue={undefined}
						isReversedValue={undefined}
					/>
					{isList ? (
						<>
							<CheckboxInput
								keyName="isArchived"
								translationKey="lists:filter.archived"
								defaultValue={undefined}
								isReversedValue={undefined}
							/>
						</>
					) : null}
				</FiltersContent>
			</>
		</Filters>
	)
}

import { MassMessages } from '../../../features/modals/pages/MassMessages'
import { MassReminders } from '../../../features/modals/pages/MassReminders'
import { MassFeedback } from '@domains/brandFeedbacks/massFeedback/MassFeedback'
import { ExportGQL } from '../../../features/modals/components/actions/ExportGQL'
import {
	EmployeeExportDocument,
	IntegrationCategory,
	TrackType,
	useUpdateManyUserIntegrationsMutation
} from '@gqlTypes'
import { MassInterviewsCreation } from '@domains/interviews/createMany/MassInterviewsCreation'
import { MassUpdatesGql } from '@features/modals/pages/MassUpdatesGql'
import { LeftBar } from '@features/lists/components/LeftBar'
import { useOrganization } from '@domains/organization/useOrganization'
import { useRole } from '@shared/hooks/useRole'
import { type ReactElement } from 'react'
import { type ApolloQueryResult } from '@apollo/client'
import { EmployeesFilters } from '../EmployeesFilter'

interface Props {
	isAllRowsSelected: boolean
	refetch: () => Promise<ApolloQueryResult<unknown>>
	dataList: { id: string }[]
	count: number
}
export const EmployeesFilterBar = ({ isAllRowsSelected, refetch, dataList, count }: Props): ReactElement => {
	const { hasOrgaSkills } = useOrganization()

	const [updateManyUserIntegrations, { loading }] = useUpdateManyUserIntegrationsMutation()
	const { screens } = useRole()

	const massAction = {
		trackType: TrackType.Position,
		integrationCategory: IntegrationCategory.Company,
		isAllRowsSelected,
		dataList
	}

	return (
		<LeftBar>
			<MassInterviewsCreation {...massAction} />
			{screens.employees.hasAdminAccess ? (
				<MassUpdatesGql
					{...massAction}
					query={updateManyUserIntegrations}
					refetch={refetch}
					isLoading={loading}
					count={count}
				/>
			) : null}

			<MassMessages {...massAction} count={count} />
			<MassReminders {...massAction} />
			{hasOrgaSkills ? <MassFeedback {...massAction} count={count} /> : null}
			<ExportGQL isAllRowsSelected={isAllRowsSelected} dataList={dataList} QUERY={EmployeeExportDocument} />
			<EmployeesFilters refetch={refetch} />
		</LeftBar>
	)
}

import { useLoginAsMutation } from '@gqlTypes'
import { firebaseAuth } from '@infrastructure/firebase/firebase.helper'
import { IconButton } from '@mui/material'
import { type ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import LoginIcon from '@mui/icons-material/Login'

interface Props {
	uid: string
}

export const LoginAs = ({ uid }: Props): ReactElement => {
	const [loginAs] = useLoginAsMutation({ variables: { uid } })
	const history = useHistory()

	const onLoginAs = async (): Promise<void> => {
		const { data } = await loginAs()
		const token = data?.loginAs ?? ''
		await firebaseAuth().signInWithCustomToken(token)
		history.push('/')
	}

	return (
		<IconButton sx={{ color: 'primary.main' }} onClick={onLoginAs}>
			<LoginIcon />
		</IconButton>
	)
}

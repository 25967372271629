import { Divider, IconButton, Tooltip } from '@mui/material'
import { type ReactElement } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

interface Props {
	text?: string
}

export const AdornmentTooltip = ({ text = '' }: Props): ReactElement | null => {
	if (text === '') return null
	return (
		<>
			<Divider orientation="vertical" sx={{ height: 28, m: 0.5 }} />
			<Tooltip title={text}>
				<IconButton>
					<InfoOutlinedIcon />
				</IconButton>
			</Tooltip>
		</>
	)
}

import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import useCheckRoleSecurity from '@shared/hooks/useRoleSecurity'
import { useGetCommunityMembersListQuery, ScreenId } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { useOrganization } from '@domains/organization/useOrganization'
import { type ReactElement, useState } from 'react'
import { Container, Typography } from '@mui/material'
import { CardList } from '@features/lists/components/CardList'
import { searchQuery } from '@features/lists/components/Search'
import { CommunityMemberCard } from './CommunityMemberCard'
import { CommunityMembersFilterBar } from './CommunityMembersFilterBar'
import { Mode, ModeToggle } from '@application/dashboard/ModeToggle'
import { CommunityMembersRouter } from '../CommunityMembers.router'
import { useHistory } from 'react-router-dom'
import { generateRoute } from '../../../router/routes'

export const CommunityMembersList = (): ReactElement => {
	const { t } = useCustomTranslation()
	const history = useHistory()
	useDocumentTitle(t('communityMembers:title'))
	useCheckRoleSecurity(ScreenId.CommunityMembers)
	const { filters } = useFilters()

	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)
	const { organizationId } = useOrganization()

	const variables = { organizationId, limit: 12, filters, searchQuery: searchQuery() }
	const { loading, data, refetch, fetchMore } = useGetCommunityMembersListQuery({
		variables
	})
	useDefaultFilter({ refetch })

	const { nodes = [], cursor = '', hasNextPage = false, count = 0 } = data?.getCommunityMembersList ?? {}

	return (
		<Container maxWidth="xl">
			<CardList
				renderItem={(item, isSelected, selectRow) => (
					<CommunityMemberCard key={item.id} position={item} isSelected={isSelected} selectRow={selectRow} />
				)}
				emptyComponent={<Typography sx={{ fontSize: 24 }}>{t('communityMembers:dataGrid.empty')}</Typography>}
				isAllRowsSelected={isAllRowsSelected}
				setIsAllRowsSelected={setIsAllRowsSelected}
				dataList={nodes}
				isLoading={loading}
				fetchMore={fetchMore}
				cursor={cursor}
				hasNextPage={hasNextPage}
				title={t('communityMembers:title', { count })}
				toolbar={
					<CommunityMembersFilterBar
						isAllRowsSelected={isAllRowsSelected}
						refetch={refetch}
						dataList={nodes}
						count={count}
					/>
				}
				headerLeftComponent={
					<ModeToggle
						mode={Mode.List}
						onToggle={() => {
							history.push(generateRoute(CommunityMembersRouter.paths.dashboard))
						}}
					/>
				}
				refetch={refetch}
			/>
		</Container>
	)
}
